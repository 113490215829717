// methodologyOptions.js

const methodologyOptions = {
    Paradigm: ['Positivist', 'Pragmatic', 'Interpretive'],
    TheoreticalPerspective: ['Post-Positivist', 'Constructivist', 'Post-Structuralist', 'Critical Theory', 'Feminist Theory'],
    Approach: ['Quantitative', 'Mixed', 'Qualitative'],
    Reasoning: ['Deductive', 'Inductive'],

    Objective: [
        'Explanatory - Cause-Effect',  
        'Predictive',
        'Comparative',
        'Analytical',
        'Developmental',
        'Instrument Development',
        'Descriptive',
        'Evaluative',
        'Exploratory',
        'Synthesis',
        'Explanatory - Meaning',
        'Theory-Generation',
        'Case-Specific' 
    ],    
    StudyControlType: ['Experimental', 'Quasi-experimental', 'Observational'],

    Design: [
        'Factorial Experiments',
        'Independent Group Comparison',
        'Dependent Group Comparison',
        'Cohort',
        'Case-Control',
        'Correlational Design',
        'Repeated Measures Design',
        'Single-Case Experimental Design',
        'Survey Design',
        'Time-Series Analysis',
        'Cross-Over Trials',
        'Exploratory Sequential Mixed Methods',
        'Explanatory Sequential Mixed Methods',
        'Systematic Review',
        'Meta-analysis',
        'Integrative Review',
        'Scoping Review',
        'Narrative Review',
        'Action Research',
        'Case Study',
        'Phenomenology',
        'Grounded Theory',
        'Ethnography',
        'Narrative Inquiry'
    ],

    DataCollectionTiming: ['Prospective', 'Longitudinal', 'Cross-sectional', 'Retrospective'],
    DataCollectionMethod: [
        'Tests',
        'Surveys',
        'Questionnaires',
        'Task-Based Assessments',
        'Behavioral Checklists',
        'Observations',
        'Archival Records',
        'Literature Screening',
        'Ecological Momentary Assessment (EMA)',
        'Document Analysis',
        'Interviews',
        'Focus Groups',
        'Reflexive Journals',
        'Virtual Ethnography'
    ],

    UnitOfAnalysis: [
        'Individuals',
        'Groups',
        'Institutions',
        'Documents/Artifacts'
    ],

    Sampling: [
        'Random',
        'Stratified Random Sampling',
        'Probability',
        'Systematic',
        'Quota Sampling',
        'Comprehensive',
        'Criterion-based',
        'Non-probability',
        'Purposive',
        'Snowball',
        'Theoretical Sampling',
        'Saturation-based',
        'Convenience'
    ],

    ControlTechniques: [
        'Randomization', // Molt experimental, assegura assignació aleatòria
        'Double-Blinding', // Clàssic en assajos clínics experimentals
        'Placebo Control', // Fortament experimental, controla efectes placebo
        'Blinding', // Redueix biaixos, experimental però menys restrictiu
        'Control Groups', // Estàndard en estudis experimentals
        'Counterbalancing', // Controla efectes d'ordre, útil en experiments
        'Repeated Measures', // Experimental però flexible en disseny
        'Matching', // Disseny quasi-experimental per reduir biaixos
        'Stratification', // Similar a Matching, permet agrupacions controlades
        'Multiple Baseline Control', // Més flexible, útil en casos específics
        'Treatment Withdrawal', // Útil per verificar efectes, menys experimental
        'Use of Benchmarks', // Pot aplicar-se en estudis observacionals
        'Historical Control' // Interpretatiu, utilitza dades prèvies
    ],    

    QualityEnhancementStrategies: [
        'Pilot Testing',
        'Test-retest',
        'Inter-rater Reliability',
        'Simulation',
        'Sequential Exploratory Strategy',
        'Triangulation',
        'Adaptive Sampling',
        'Iterative Refinement',
        'Constant Comparison',
        'Thematic Saturation',
        'Participatory Feedback Loops',
        // Opcions addicionals si s'escau
        'Validity Testing',
        'Reliability Testing',
        'Data Cleaning'
    ],

    DataType: [
        'Numeric Data',
        'Physiological Measures',
        'Geospatial Data',
        'Social Media Data',
        'Images',
        'Audio/Video Data',
        'Textual Data'
    ],

    VariableType: [
        'Continuous (Ratio)',
        'Continuous (Interval)',
        'Ordinal',
        'Nominal'
    ],

    DataAnalysisStrategies: [
        'Inferential Statistics',
        'Chi-square and Contingency Table Analysis',
        'Logistic Regression',
        'Correspondence Analysis',
        'Multivariate Analysis',
        'Descriptive Statistics',
        'Survival Analysis',
        'Sequential Analysis',
        'Structural Equation Modeling (SEM)',
        'Latent Class Analysis',
        'Bayesian Analysis',
        'Cluster Analysis',
        'Mosaic Plots',
        'Diversity Indices',
        'Visual Analytics',
        'Mixed Methods Integration',
        'Sentiment Analysis',
        'Content Analysis',
        'Thematic Analysis',
        'Grounded Theory Coding',
        'Open Coding',
        'Axial Coding',
        'Selective Coding',
        'Phenomenological Analysis',
        'Narrative Analysis',
        'Case-Specific Analysis',
        'Cross-Case Analysis'
    ],

    EthicalConsiderations: [
        'Ethical Committee Approval',
        'Informed Consent',
        'Anonymization',
        'Confidentiality',
        'Data Protection'
    ]
};

export default methodologyOptions;