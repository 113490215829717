// TallerStudentSessionJoiner.jsx

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import pusher from '../../../auth-and-backend/pusher';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';

const TallerStudentSessionJoiner = ({ onSessionJoined }) => {
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const [studentName, setStudentName] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0); // Desplaçar-se a la part superior quan es carrega el component
  }, []);

  useEffect(() => {
    // Subscriu-te a l'esdeveniment quan un estudiant s'uneix a una sessió
    const channel = pusher.subscribe('my-channel');
    
    channel.bind('server-student-joined', (data) => {
      console.log('Pusher event received:', data);
      // Aquí pots fer alguna cosa amb les dades si ho necessites
    });

    return () => {
      // Neteja dels esdeveniments quan es desmunta el component
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);

  const joinSession = () => {
    // const joinData = { code, studentName }; // Aquesta línia es pot eliminar
    
    // Crida a l'API per unir-se a la sessió
    axios.post(`${apiUrl}/sessions/join`, { code, studentId: studentName })
      .then(response => {
        const selectedSample = response.data.selectedSample;
        onSessionJoined(code, selectedSample, studentName);
        // El servidor emetrà un esdeveniment de Pusher quan un estudiant s'uneixi a la sessió
      })
      .catch(error => {
        console.error('Error joining session:', error);
      });
  };  

  const handleInputChange = (e) => {
    setCode(e.target.value);
    adjustInputWidth(e.target);
  };

  const adjustInputWidth = (input) => {
    input.style.width = `${input.value.length + 1}ch`;
  };

  return (
    <div>
      <h1>{t('cape_v_workshop_student_view')}</h1>
      <div className="button-container">
        <input
          type="text"
          className="input-field"
          placeholder={t('session_code')}
          value={code}
          onChange={handleInputChange}
        />
        <input
          type="text"
          className="input-field"
          placeholder={t('student_name')}
          value={studentName}
          onChange={(e) => setStudentName(e.target.value)}
        />
        <button className="button-center" onClick={joinSession}>{t('join_session')}</button>
      </div>
    </div>
  );
};

export default TallerStudentSessionJoiner;