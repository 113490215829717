// Categories.jsx

import React from 'react';
import { Droppable, Draggable } from '@hello-pangea/dnd';

function Categories({ categories, setCategories, handleDeleteCategory }) {
  const handleRenameCategory = (id, newName) => {
    setCategories((prev) =>
      prev.map((cat) =>
        cat.id === id ? { ...cat, name: newName } : cat
      )
    );
  };

  return (
    <div>
      <h3>Categories</h3>
      {categories.map((category) => (
        <Droppable key={category.id} droppableId={category.id}>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                border: '1px solid #ddd',
                padding: '10px',
                borderRadius: '4px',
                minHeight: '200px',
                marginBottom: '10px',
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <input
                  type="text"
                  value={category.name}
                  onChange={(e) => handleRenameCategory(category.id, e.target.value)}
                  placeholder="Category name..."
                  style={{
                    marginBottom: '10px',
                    width: '80%',
                    padding: '5px',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                  }}
                />
                {handleDeleteCategory && (
                  <button
                    onClick={() => handleDeleteCategory(category.id)}
                    className="button-important"
                  >
                    Delete
                  </button>
                )}
              </div>

              {/* Llista de conceptes dins la categoria */}
              {category.concepts.map((concept, index) => (
                <Draggable
                  key={concept.id}
                  draggableId={concept.id}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        padding: '5px',
                        margin: '5px 0',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        backgroundColor: '#fff',
                        ...provided.draggableProps.style,
                      }}
                    >
                      {concept.label}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      ))}
    </div>
  );
}

export default Categories;