// CAPEVRCitation.jsx
// TODO actualitzar cita

import React from 'react';
import { useTranslation } from 'react-i18next';

function CAPEVRCitation() {
    const { t } = useTranslation();

  return (
    <div className="cites">
         
          <div>
            Kempster, Nagle & Solomon (2024). Títol i DOI definitiu. <a href="https://doi.org/10.1044/1058-0360(2008/08-0017)" target="_blank" rel="noopener noreferrer">https://doi.org/10.1044/1058-0360(2008/08-0017)</a><br />. {t('reprinted_with_permission')}.<br /><br />
          </div>

      </div>
  );
}

export default CAPEVRCitation;