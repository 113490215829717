// AdminDashboard.jsx

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './../../locales/i18n';
import { useAuth } from '../../auth-and-backend/auth/authContext';
import LoginModal from '../../auth-and-backend/modals/LoginModal';
import SignUpModal from '../../auth-and-backend/modals/SignUpModal';
import UpdateConsentModal from '../../auth-and-backend/modals/UpdateConsentModal';
import axios from 'axios';

function AdminDashboard() {
    const { t } = useTranslation();
    const { user, setUser } = useAuth();
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);
    const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);
    const [isUpdateConsentModalOpen, setUpdateConsentModalOpen] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';
    
    useEffect(() => {
        if (user && user.acceptResearchUsage === null) {
            setUpdateConsentModalOpen(true);
        }
    }, [user]);

    const openLoginModal = () => setLoginModalOpen(true);
    const closeLoginModal = () => setLoginModalOpen(false);
    const openSignUpModal = () => setSignUpModalOpen(true);
    const closeSignUpModal = () => setSignUpModalOpen(false);

    const handleAcceptUpdateConsent = (consentGiven) => {
        console.log("Attempting to update consent for user:", user._id, "with consent:", consentGiven);
        axios.put(`${apiUrl}/users/${user._id}/update-consent`, { acceptResearchUsage: consentGiven }, {
            headers: { Authorization: `Bearer ${user.token}` },
        })        
        .then(response => {
            console.log("Response from server:", response.data);
            setUser({ ...user, acceptResearchUsage: consentGiven });
            setUpdateConsentModalOpen(false); // Tanca el modal
        })
        .catch(error => {
            console.error('Error updating consent:', error);
        });
    };

    return (
        <div className="main-content">
            {user ? (
                <div className="welcome-message">
                    <h1>Admin Dashboard</h1>
                    <h1>{t('hello')}, {user.username}!</h1>
                    <p>{t('welcome_back_message')}</p>
                </div>
            ) : (
                <div className="hero">
                    <div className="hero-content">
                        <h1 className="hero h1">All-Voiced</h1>
                        <h2 className="hero h2">{t('landing_title')}</h2>
                        <p>{t('landing_presentation')}</p>
                        <h3 >{t('global_database_title')}</h3>
                        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                            <img 
                                src="/images/global-database-icon-negative.png" 
                                alt="Global Database Icon" 
                                className="centered-icon" 
                                style={{ maxWidth: '150px', height: 'auto' }}
                            />
                        </div>
                        <p>{t('landing_presentation_two')}</p>
                        <p style={{ fontWeight: 'bold' }}>{t('landing_cta')}</p>
                        <div className="hero-buttons">
                            <button onClick={openSignUpModal} className="hero-button">{t('sign_up_for_free')}</button>
                            <button onClick={openLoginModal} className="hero-button">{t('login')}</button>
                        </div>
                        <br/>
                    </div>
                </div>
            )}

            <div className="icon-button-container">
                
                <div className="icon-container">
                    <Link to="/resources" className="icon-button">
                        <img src="/images/resources-icon.png" alt="Resources Icon" className="link-icon" />
                        <span className="link-text">{t('training_resources')}</span>
                    </Link>
                </div>

                <div className="icon-container">
                    <Link to="/studies" className="icon-button">
                        <img src="/images/participate-icon.png" alt="Participate Icon" className="link-icon" />
                        <span className="link-text">{t('study_engagement_platform')}</span>
                    </Link>
                </div>

                <div className="icon-container">
                    <Link to="/services" className="icon-button">
                        <img src="/images/services-icon.png" alt="Services Icon" className="link-icon" />
                        <span className="link-text">{t('custom_research_solutions')}</span>
                    </Link>
                </div>

            </div>

            <div className="icon-button-container">
                
                <div className="icon-container">
                    <Link to="/resources" className="icon-button">
                        <img src="/images/daily-challenge-icon.png" alt="Daily Challenge Icon" className="link-icon" />
                        <span className="link-text">{t('daily_challenge')}</span>
                    </Link>
                </div>

            </div>

            <LoginModal
                isOpen={isLoginModalOpen}
                onRequestClose={closeLoginModal}
                openSignUpModal={openSignUpModal}
            />
            <SignUpModal
                isOpen={isSignUpModalOpen}
                onRequestClose={closeSignUpModal}
                openLoginModal={openLoginModal}
            />
            <UpdateConsentModal
                isOpen={isUpdateConsentModalOpen}
                onUpdateConsent={handleAcceptUpdateConsent}
            />
        </div>
    );
}

export default AdminDashboard;