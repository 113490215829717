// availableConcepts.js
// TODO actualitzar la llista de conceptes

const availableConcepts = [
  { id: '1', label: 'Accurate Pitch' },
  { id: '2', label: 'Adequate Loudness' },
  { id: '3', label: 'Adequate Pitch' },
  { id: '4', label: 'Aphonic Break' },
  { id: '5', label: 'Aphonia' },
  { id: '6', label: 'Asthenia' },
  { id: '7', label: 'Back Oral Resonance' },
  { id: '8', label: 'Breathiness' },
  { id: '9', label: 'Diplophonia' },
  { id: '10', label: 'Falsetto' },
  { id: '11', label: 'Front Oral Resonance' },
  { id: '12', label: 'Frequency Range' },
  { id: '13', label: 'Fry' },
  { id: '14', label: 'Hypernasality' },
  { id: '15', label: 'Hyponasality' },
  { id: '16', label: 'Lack of Oral Resonance' },
  { id: '17', label: 'Modal Register' },
  { id: '18', label: 'Normal Voice Quality' },
  { id: '19', label: 'Out of Tune' },
  { id: '20', label: 'Perceived Duration' },
  { id: '21', label: 'Pitch Break' },
  { id: '22', label: 'Pitch Instability' },
  { id: '23', label: 'Pitch Stability' },
  { id: '24', label: 'Resonance' },
  { id: '25', label: 'Roughness' },
  { id: '26', label: 'Spasm' },
  { id: '27', label: 'Strain' },
  { id: '28', label: 'Too High' },
  { id: '29', label: 'Too Low' },
  { id: '30', label: 'Too Loud' },
  { id: '31', label: 'Too Soft' },
  { id: '32', label: 'Tremor' },
  { id: '33', label: 'Wet Voice' },
];
  
  export default availableConcepts;
  