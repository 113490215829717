// LanguageSwitcher.jsx

import React, { useEffect } from 'react';
import { useAuth } from './../auth-and-backend/auth/authContext';
import { useTranslation } from 'react-i18next';

function LanguageSwitcher({ roleIds }) {
  const { i18n } = useTranslation();
  const { user } = useAuth();

  // Funció per canviar d'idioma i desar-lo a localStorage
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('selectedLanguage', language); // Desa l'idioma seleccionat
  };

  // Carrega l'idioma desat quan el component es carrega
  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const userRole = user ? user.role : null;

  return (
    <select 
      onChange={(e) => changeLanguage(e.target.value)}
      value={i18n.language} // Reflect the current language
    >
      <option value="my">Bahasa Melayu</option>
      <option value="cat">Català</option>
      <option value="en">English</option>
      <option value="es">Español</option>
      <option value="fr">Français</option>
      <option value="jp">日本語</option>
      {/* Add other languages */}
      {roleIds && roleIds['admin'] && userRole === roleIds['admin'] && (
        <>
          <option value="fr">Français (Europe)</option>
          <option value="fr-CA">Français (Québec)</option>
          <option value="pt-br">Português (Brasil)</option>
          <option value="fa">فارسی</option>
        </>
      )}
      {roleIds && roleIds['collaborator'] && userRole === roleIds['collaborator'] && (
        <>
          <option value="fr-CA">Français (Québec)</option>
          <option value="pt-br">Português (Brasil)</option>
          <option value="fa">فارسی</option>
        </>
      )}
    </select>
  );
}

export default LanguageSwitcher;