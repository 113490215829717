// ScaleCustomization.jsx

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const ScaleCustomization = ({
  selectedAttrs = [],
  scales,
  setScales,
  onBack,
  onProceed,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);

  useEffect(() => {
    const updatedScales = selectedAttrs.map((attr) => {
      const existingScale = scales.find((scale) => scale.id === attr.id);
      return (
        existingScale || {
          id: attr.id,
          translationKey: attr.translationKey,
          absence: false,
          ordinalValue: false,
          value: false,
          comment: false,
          variability: false,
        }
      );
    });

    setScales(updatedScales);
  }, [selectedAttrs, scales, setScales]);

  const updateScale = (id, key, value) => {
    setScales((prev) =>
      prev.map((item) =>
        item.id === id
          ? {
              absence: false,
              ordinalValue: false,
              value: false,
              comment: false,
              variability: false,
              ...item,
              [key]: value,
            }
          : item
      )
    );
    setError(false);
  };

  const setGlobalScale = (key, value) => {
    setScales((prev) =>
      prev.map((item) => ({
        ...item,
        absence: key === "absence" ? value : item.absence,
        ordinalValue: key === "ordinalValue" ? value : item.ordinalValue,
        value: key === "value" ? value : item.value,
        comment: key === "comment" ? value : item.comment,
        variability: key === "variability" ? value : item.variability,
      }))
    );
    setError(false);
  };

  const handleBuild = () => {
    if (
      scales.some(
        (scale) =>
          !scale.absence &&
          !scale.ordinalValue &&
          !scale.value &&
          !scale.comment &&
          !scale.variability
      )
    ) {
      setError(true);
      return;
    }
    setError(false);
    onProceed();
  };

  return (
    <div style={{ padding: "20px", margin: "0 auto" }}>
      <h2>{t("customize_scales")}</h2>

      <table className="table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th>{t("attribute")}</th>
            <th>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  onChange={(e) => setGlobalScale("absence", e.target.checked)}
                  style={{ marginBottom: "5px" }} // Interlineat extra
                />
                <span>{t("yes_no")}</span>
              </div>
            </th>
            <th>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  onChange={(e) =>
                    setGlobalScale("ordinalValue", e.target.checked)
                  }
                  style={{ marginBottom: "5px" }} // Interlineat extra
                />
                <span>{t("0_3_scale")}</span>
              </div>
            </th>
            <th>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  onChange={(e) => setGlobalScale("value", e.target.checked)}
                  style={{ marginBottom: "5px" }} // Interlineat extra
                />
                <span>{t("vas_scale")}</span>
              </div>
            </th>
            <th>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  onChange={(e) => setGlobalScale("comment", e.target.checked)}
                  style={{ marginBottom: "5px" }} // Interlineat extra
                />
                <span>{t("comment")}</span>
              </div>
            </th>
            <th>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  onChange={(e) =>
                    setGlobalScale("variability", e.target.checked)
                  }
                  style={{ marginBottom: "5px" }} // Interlineat extra
                />
                <span>{t("variability")}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {scales.map((attr) => (
            <tr key={attr.id}>
              <td>{t(attr.translationKey)}</td>
              <td>
                <input
                  type="checkbox"
                  checked={attr.absence}
                  onChange={(e) =>
                    updateScale(attr.id, "absence", e.target.checked)
                  }
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={attr.ordinalValue}
                  onChange={(e) =>
                    updateScale(attr.id, "ordinalValue", e.target.checked)
                  }
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={attr.value}
                  onChange={(e) =>
                    updateScale(attr.id, "value", e.target.checked)
                  }
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={attr.comment}
                  onChange={(e) =>
                    updateScale(attr.id, "comment", e.target.checked)
                  }
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={attr.variability}
                  onChange={(e) =>
                    updateScale(attr.id, "variability", e.target.checked)
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {error && (
        <p style={{ color: "red", textAlign: "center", marginTop: "10px" }}>
          {t("error_select_scale_for_all")}
        </p>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <button className="button-center" onClick={onBack}>
          {t("back_to_attributes_selection")}
        </button>
        <button className="button-center" onClick={handleBuild}>
          {t("build_resource")}
        </button>
      </div>
    </div>
  );
};

export default ScaleCustomization;