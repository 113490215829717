// PresetResources.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import './../../locales/i18n';
import { Link } from 'react-router-dom';
// import NavigationIcons from './../app-views/NavigationIcons';
//import React, { useState } from 'react';
//import { Link, useNavigate } from 'react-router-dom';
//import { useAuth } from './../../auth-and-backend/auth/authContext';
//import LoginModal from './../../auth-and-backend/modals/LoginModal';
//import SignUpModal from './../../auth-and-backend/modals/SignUpModal';

function PresetResources() {
    const { t } = useTranslation();
    //const { user } = useAuth();
    //const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    //const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
    //const navigate = useNavigate();

    // const handleRestrictedLinkClick = (e) => {
    //     if (!user) {
    //         e.preventDefault();
    //         setIsLoginModalOpen(true);
    //     }
    // };

    // const handleRestrictedButtonClick = (profile) => {
    //     if (!user) {
    //         setIsLoginModalOpen(true);
    //     } else {
    //         handleProfileSelect(profile);
    //     }
    // };

    // const renderLockIcon = (isRestricted) => {
    //     return isRestricted ? (
    //         user ? (
    //             <i className="fas fa-lock-open" style={{ color: '#a5d3ce' }}></i>
    //         ) : (
    //             <i className="fas fa-lock" style={{ color: 'red' }}></i>
    //         )
    //     ) : null;
    // };

    // const handleProfileSelect = (profile) => {
    //     const path = profile === 'instructor' ? '/cape-v-workshop-instructor' : '/cape-v-workshop-student';
    //     navigate(path, { state: { profile } });
    // };

    return (
        <div className="icon-button-container">

                <div className="icon-container">
                    <Link to="/resources-for-autonomous-training" className="icon-button">
                        <img src="/images/training-icon.png" alt="Training Icon" className="link-icon" />
                        <span className="link-text">{t('resources_for_autonomous_training_title')}</span>
                    </Link>
                </div>

                <div className="icon-container">
                    <Link to="/resources-for-teaching-and-learning" className="icon-button">
                        <img src="/images/teaching-icon.png" alt="Teaching Icon" className="link-icon" />
                        <span className="link-text">{t('resources_for_teaching_and_learning_title')}</span>
                    </Link>
                </div>

                <div className="icon-container">
                    <Link to="/resources-for-research" className="icon-button">
                        <img src="/images/research-icon.png" alt="Research Icon" className="link-icon" />
                        <span className="link-text">{t('resources_for_research_title')}</span>
                    </Link>
                </div>

                <div className="icon-container">
                    <Link to="/concepts" className="icon-button">
                        <img src="/images/concepts-icon.png" alt="Concepts Icon" className="link-icon" />
                        <span className="link-text">{t('resources_for_conceptual_organization')}</span>
                    </Link>
                </div>
            </div>
    );
}

export default PresetResources;