// EARV.jsx

// TODO s'ha d'activar l'audioUploader, que donava error perquè no s'estava fent servir uploadedAudio. 
// Quan pugui, crear component de resultats per passar el valor de uploadedAudio i ja està

import React, { useState } from 'react';
import TreeMenu from './TreeMenu';
import YouTubeAudioPlayer from './../../shared-files/tools-and-functions/YouTubeAudioPlayer';
//import AudioUploader from './../../shared-files/tools-and-functions/AudioUploader';
import { useTranslation } from 'react-i18next';

const EARV = () => {
  const { t } = useTranslation();
  const [selectedVariables, setSelectedVariables] = useState([]);
  const [selectionMode, setSelectionMode] = useState(null); // null until a mode is selected
 // const [uploadedAudio, setUploadedAudio] = useState(null);
  const [selectedSinger, setSelectedSinger] = useState(null);
  const [randomSample, setRandomSample] = useState(null);

  const audioSamples = [
    'KOwOzlv9qAc',
    'ej9CeXX2L10',
    '0Ckt3YQCxrA',
    'Hqa4q2WG6Dk',
    'jYHHS5Bm-B4',
    'unbo6GYa-DU',
    'MEkByCztea0',
    '6XG0DKR1pPQ',
    '5tIwOgmm6YI',
    'tBZgVvCIjPc',
    '6IZI3f53S6U',
    '-Xp1zivrI1Q',
    '3nz043UTH9s',
    'd7WswlgwZDw',
  ];

  const singers = [
    'Beyoncé',
    'Cecilia Bartoli',
    'Céline Dion',
    'Christina Aguilera',
    'Damien Lawson',
    'Elvis Presley',
    'Jennifer Hudson',
    'Justin Bieber',
    'Mariah Carey',
    'Ricco Robinson',
    'Tonton Alex',
    'Usher',
    'Whitney Houston',
    'Yoel Pazos',
  ];

  const handleSelectionChange = (variables) => {
    setSelectedVariables(variables);
    console.log('Selected Variables:', variables);
  };

  const handleSingerSelect = (event) => {
    const selectedIndex = event.target.value;
    if (selectedIndex !== "") {
      setSelectedSinger(audioSamples[selectedIndex]);
      setSelectionMode('select');
    }
  };

  const handleRandomSample = () => {
    const randomIndex = Math.floor(Math.random() * audioSamples.length);
    setRandomSample(audioSamples[randomIndex]);
    setSelectionMode('random');
  };

  const renderContent = () => {
    if (selectionMode === 'random') {
      return <YouTubeAudioPlayer audioSamples={[randomSample]} />;
    }
    if (selectionMode === 'select') {
      return (
        <div>
          {selectedSinger && <YouTubeAudioPlayer audioSamples={[selectedSinger]} />}
        </div>
      );
    }
    if (selectionMode === 'upload') {
 //     return <AudioUploader onFileUpload={(file) => setUploadedAudio(file)} />;
    }
    return <p>{t('please_select_an_option_to_begin')}</p>;
  };

  return (
    <div>
      <h1>Extendable Auditory Ratings for Voices (EAR-V)</h1>
      <div>
        <h2>{t('choose_mode')}</h2>
        <button className="button-center" onClick={handleRandomSample}>{t('random_sample')}</button>
        <select onChange={handleSingerSelect} defaultValue="">
          <option value="" disabled>{t('select_a_sample')}</option>
          {singers.map((singer, index) => (
            <option key={index} value={index}>
              {singer}
            </option>
          ))}
        </select>
        <button className="button-center" onClick={() => setSelectionMode('upload')}>{t('upload_your_own')}</button>
      </div>
      <div style={{ marginTop: '20px' }}>{renderContent()}</div>
      <br />
      <TreeMenu onSelectionChange={handleSelectionChange} />
      <div>
        <h2>{t('selected_variables')}</h2>
        <ul>
          {selectedVariables.map((variable) => (
            <li key={variable}>{variable}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default EARV;