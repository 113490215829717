// ConceptManager.jsx

import React, { useState } from 'react';
import baseConcepts from './concepts';
import ConceptList from './ConceptList';
import TreeView from './TreeView';
import { buildTreeFromItems } from './utils';

function ConceptManager() {
  const [items, setItems] = useState([]);
  const [available, setAvailable] = useState(baseConcepts);
  const [newConcept, setNewConcept] = useState('');

  const handleAddConcept = (concept) => {
    setItems((prev) => [...prev, { ...concept, level: 0 }]);
    setAvailable((prev) => prev.filter((c) => c.id !== concept.id));
  };

  const handleAddCustomConcept = () => {
    if (!newConcept.trim()) return;
    const newConceptObj = {
      id: Date.now().toString(),
      label: newConcept.trim(),
    };
    setAvailable((prev) => [...prev, newConceptObj]);
    setNewConcept('');
  };

  const handleExport = () => {
    const tree = buildTreeFromItems(items);
    const json = JSON.stringify(tree, null, 2);
    const blob = new Blob([json], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = 'outliner.json';
    link.href = url;
    link.click();
    URL.revokeObjectURL(url);
    alert('Fitxer JSON descarregat correctament!');
  };

  return (
    <div className="concept-manager-container">
      {/* Conceptes disponibles */}
      <div className="concept-panel">
        <h3>Conceptes disponibles</h3>
        {available.map((c) => (
         <div key={c.id} className="concept-item">
         {c.label}
         <button className="editable-button-plus" onClick={() => handleAddConcept(c)}>
           +
         </button>
       </div>       
        ))}
        <div className="add-custom-concept">
          <input
            type="text"
            className="input-field"
            value={newConcept}
            onChange={(e) => setNewConcept(e.target.value)}
            placeholder="Nou concepte..."
          />
          <button className="button" onClick={handleAddCustomConcept}>
            Afegir nou concepte
          </button>
        </div>
      </div>

      {/* Llista editable */}
      <ConceptList
        items={items}
        setItems={setItems}
        handleReturnToAvailable={(index) =>
          setItems((prev) => {
            const itemToReturn = prev[index];
            setAvailable((avail) => [...avail, itemToReturn]);
            return prev.filter((_, i) => i !== index);
          })
        }
      />

      {/* Arbre jeràrquic */}
      <div className="tree-view-container">
        <h3>Visualització de la jerarquia</h3>
        <TreeView tree={buildTreeFromItems(items)} />
      </div>

      {/* Botó d'exportació JSON */}
      <div className="export-container">
        <button className="button button-center" onClick={handleExport}>
          Exportar JSON
        </button>
      </div>
    </div>
  );
}

export default ConceptManager;