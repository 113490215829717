// availableAttributes.js
// TODO actualitzar la llista d'atributs

const availableAttributes = [
    { id: "1", label: "aphonia", translationKey: "aphonia" },
    { id: "2", label: "aphonicBreak", translationKey: "aphonic_break" },
    { id: "3", label: "asthenia", translationKey: "asthenia" },
    { id: "4", label: "backResonance", translationKey: "back_resonance" },
    { id: "5", label: "breathiness", translationKey: "breathiness" },
    { id: "6", label: "diplophonia", translationKey: "diplophonia" },
    { id: "7", label: "falsetto", translationKey: "falsetto" },
    { id: "8", label: "frontResonance", translationKey: "front_resonance" },
    { id: "9", label: "fry", translationKey: "fry" },
    { id: "10", label: "hardGlottalAttack", translationKey: "hard_glottal_attack" },
    { id: "11", label: "hypernasality", translationKey: "hypernasality" },
    { id: "12", label: "hyponasality", translationKey: "hyponasality" },
    { id: "13", label: "normalLoudness", translationKey: "normal_loudness" },
    { id: "14", label: "normalNasality", translationKey: "normal_nasality" },
    { id: "15", label: "normalPitch", translationKey: "normal_pitch" },
    { id: "16", label: "overallSeverity", translationKey: "overall_severity" },
    { id: "17", label: "pitchBreak", translationKey: "pitch_break" },
    { id: "18", label: "pitchInstability", translationKey: "pitch_instability" },
    { id: "19", label: "roughness", translationKey: "roughness" },
    { id: "20", label: "spasm", translationKey: "spasm" },
    { id: "21", label: "strain", translationKey: "strain" },
    { id: "22", label: "tooHighPitch", translationKey: "too_high_pitch" },
    { id: "23", label: "tooLoudLoudness", translationKey: "too_loud_loudness" },
    { id: "24", label: "tooLowPitch", translationKey: "too_low_pitch" },
    { id: "25", label: "tooSoftLoudness", translationKey: "too_soft_loudness" },
    { id: "26", label: "tremor", translationKey: "tremor" },
    { id: "27", label: "wetGurgly", translationKey: "wet_gurgly" },
  ];
  
  export default availableAttributes;
    