// Footer.jsx

// TODO Afegir-hi els nous menús de Resource Toolbox, Study Engagement Platform i Custom Research Solutions

import React, { useState } from 'react';
import ContactFormModal from './../../auth-and-backend/modals/ContactFormModal';
import TranslationIssuesModal from './../../auth-and-backend/modals/TranslationIssuesModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faOrcid, faTwitter, faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'; 
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation();
    const [isContactModalOpen, setContactModalOpen] = useState(false);
    const [isTranslationModalOpen, setTranslationModalOpen] = useState(false);

    const openContactModal = () => {
        setContactModalOpen(true);
    };

    const closeContactModal = () => {
        setContactModalOpen(false);
    };

    const openTranslationModal = () => { 
        setTranslationModalOpen(true);
    };

    const closeTranslationModal = () => { 
        setTranslationModalOpen(false);
    };

    return (
        <footer>
            <div className="app-footer">
                <div className="footer-column">
                    <p>{t('resources')}</p>
                    <a href="/resources-for-autonomous-training">{t('resources_for_autonomous_training_title')}</a><br/>
                    <a href="/resources-for-teaching-and-learning">{t('resources_for_teaching_and_learning_title')}</a><br/>
                    <a href="/resources-for-research">{t('resources_for_research_title')}</a><br/>
                    <a href="/all-the-resources">{t('all_the_resources_title')}</a>
                </div>
                <div className="footer-column">
                    <p>All-Voiced</p>
                    <a href="/about-us">{t('about_us')}</a><br/>
                    <a href="/research-at-all-voiced">{t('research_at_allvoiced')}</a><br/>
                    <a href="#!" onClick={openTranslationModal}>{t('help_us_improve_our_language_adaptations')}</a>
                    {/*<a href="/team">{t('team')}</a><br/>
                    <a href="/careers">{t('careers')}</a><br/>
                    <a href="/partners">{t('partners')}</a>*/}
                </div>
                <div className="footer-column">
                    <p>{t('information')}</p>
                    <a href="/legal-notice">{t('legal_notice')}</a><br/>
                    <a href="/privacy-policy">{t('privacy_policy')}</a><br/>
                </div>
                <div className="footer-column">
                    <p>{t('developed_by')} <a href="https://neuscalaf.com/" target="_blank" rel="noopener noreferrer">Neus Calaf, PhD, SLP</a>.<br/> {t('all_rights_reserved')}</p>
                    <div className="social-media-links">
                        <a href="https://orcid.org/0009-0003-8286-6528" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faOrcid} />
                        </a>
                        <a href="https://linkedin.com/in/neuscalaf" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                        <a href="https://facebook.com/neuscalaf" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                        <a href="https://instagram.com/neuscalaf" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a href="https://twitter.com/neuscalaf" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faTwitter} />
                        </a>
                    </div>
                    <a href="/how-to-cite">{t('how_to_cite')}</a><br/>
                    <button 
                        onClick={openContactModal} 
                        className="contact-button"
                    >
                        {t('contact')}
                    </button>

                </div>
            </div>
            <ContactFormModal isOpen={isContactModalOpen} onRequestClose={closeContactModal} />
            <TranslationIssuesModal isOpen={isTranslationModalOpen} onRequestClose={closeTranslationModal} />
        </footer>
    );
}

export default Footer;