// ConceptsGrouping.jsx

import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext } from '@hello-pangea/dnd';
import AvailableConcepts from './AvailableConcepts';
import Categories from './Categories';
import axios from 'axios';
import availableConceptsData from './concepts';
import Feedback from './Feedback';
import YourCategorization from './YourCategorization';
import { fetchUserData } from './../../../auth-and-backend/auth/userUtils';
import ConsentModal from './ConsentModal';

const reorderList = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

function ConceptGrouping() {
  const [availableConcepts, setAvailableConcepts] = useState(availableConceptsData);
  const [categories, setCategories] = useState([
    { id: 'cat1', name: 'Category 1 (rename as needed)', concepts: [] },
    { id: 'cat2', name: 'Category 2 (rename as needed)', concepts: [] },
  ]);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [observations, setObservations] = useState('');
  const [showConsentModal, setShowConsentModal] = useState(false);
  const [consentGiven, setConsentGiven] = useState(null); 
  const [otherUsersCategorizations, setOtherUsersCategorizations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showFeedback, setShowFeedback] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';

  const [userId, setUserId] = useState(null);
  const [sociodemographicData, setSociodemographicData] = useState({});

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await fetchUserData(apiUrl);
        setUserId(userData.id);
        setSociodemographicData(userData);
      } catch (error) {
        console.error('Error initializing user data:', error);
      }
    };

    fetchUser();
  }, [apiUrl]);

  const handleAddCategory = () => {
    if (!newCategoryName.trim()) return;
    const newCategory = {
      id: 'cat' + Date.now().toString(),
      name: newCategoryName.trim(),
      concepts: [],
    };
    setCategories((prev) => [...prev, newCategory]);
    setNewCategoryName('');
  };

  const handleDeleteCategory = (categoryId) => {
    setCategories((prev) => prev.filter((cat) => cat.id !== categoryId));
  };

  const fetchFeedback = useCallback(async (page) => {
    try {
      const response = await axios.get(`${apiUrl}/drag-and-drop/feedback?page=${page}&limit=1`);
      setOtherUsersCategorizations(response.data.data);
      setTotalPages(response.data.pages);
      setCurrentPage(response.data.page);
    } catch (error) {
      console.error('Error fetching feedback data:', error);
    }
  }, [apiUrl]);

  const handleSubmit = async () => {
    if (consentGiven) {
      const dataToSend = {
        userId,
        informedConsentDragAndDrop: consentGiven,
        sociodemographicData,
        categories: categories.map((cat) => ({
          categoryName: cat.name,
          concepts: cat.concepts,
        })),
        observations: observations.trim(),
      };

      try {
        await axios.post(`${apiUrl}/drag-and-drop`, dataToSend);
        alert('Your results have been successfully submitted for research purposes.');
      } catch (error) {
        console.error('Error submitting data:', error);
        alert('There was an error submitting your results. Please try again later.');
      }
    }
    setShowFeedback(true);
    fetchFeedback(1);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === 'availableConcepts') {
        const newArr = reorderList(availableConcepts, source.index, destination.index);
        setAvailableConcepts(newArr);
        return;
      }
      if (source.droppableId.startsWith('cat')) {
        setCategories((prev) =>
          prev.map((cat) => {
            if (cat.id === source.droppableId) {
              const newConcepts = reorderList(cat.concepts, source.index, destination.index);
              return { ...cat, concepts: newConcepts };
            }
            return cat;
          })
        );
        return;
      }
    }
    if (source.droppableId === 'availableConcepts' && destination.droppableId.startsWith('cat')) {
      const concept = availableConcepts[source.index];
      const newAvail = Array.from(availableConcepts);
      newAvail.splice(source.index, 1);
      setAvailableConcepts(newAvail);
      setCategories((prev) =>
        prev.map((cat) => {
          if (cat.id === destination.droppableId) {
            const newConcepts = Array.from(cat.concepts);
            newConcepts.splice(destination.index, 0, concept);
            return { ...cat, concepts: newConcepts };
          }
          return cat;
        })
      );
      return;
    }
    if (source.droppableId.startsWith('cat') && destination.droppableId === 'availableConcepts') {
      const sourceCategory = categories.find((cat) => cat.id === source.droppableId);
      const concept = sourceCategory.concepts[source.index];
      setCategories((prev) =>
        prev.map((cat) =>
          cat.id === source.droppableId
            ? { ...cat, concepts: cat.concepts.filter((_, idx) => idx !== source.index) }
            : cat
        )
      );
      const newAvail = Array.from(availableConcepts);
      newAvail.splice(destination.index, 0, concept);
      setAvailableConcepts(newAvail);
      return;
    }
    if (source.droppableId.startsWith('cat') && destination.droppableId.startsWith('cat')) {
      const sourceCategory = categories.find((cat) => cat.id === source.droppableId);
      const concept = sourceCategory.concepts[source.index];
      setCategories((prev) =>
        prev.map((cat) => {
          if (cat.id === source.droppableId) {
            return { ...cat, concepts: cat.concepts.filter((_, idx) => idx !== source.index) };
          }
          if (cat.id === destination.droppableId) {
            const newConcepts = Array.from(cat.concepts);
            newConcepts.splice(destination.index, 0, concept);
            return { ...cat, concepts: newConcepts };
          }
          return cat;
        })
      );
      return;
    }
  };

  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      {/* Introducció */}
      <div style={{ marginBottom: '20px', textAlign: 'center' }}>
        <h2>Perceptual Attribute Categorization Task</h2>
        <p>
          This task focuses on categorizing perceptual attributes relevant to the evaluation of
          artistic voice (both sung and spoken), in both healthy and disordered conditions.
        </p>
        <p>Instructions:</p>
        <p>1. Drag and drop the available concepts into categories.</p>
        <p>2. Create new categories and rename them to suit your needs.</p>
        <p>3. Once you’ve finished, share your results to contribute to research.</p>
      </div>
  
      {/* Drag and Drop Context */}
      <DragDropContext onDragEnd={onDragEnd}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <AvailableConcepts
            availableConcepts={availableConcepts}
            setAvailableConcepts={setAvailableConcepts}
          />
          <Categories
            categories={categories}
            setCategories={setCategories}
            handleDeleteCategory={handleDeleteCategory}
          />
          <div style={{ marginTop: '20px' }}>
            <input
              type="text"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              placeholder="Add new category..."
              className="input-field"
              style={{ textAlign: 'left' }}
            />
            <button onClick={handleAddCategory} className="button-center">
              Add Category
            </button>
          </div>
        </div>
      </DragDropContext>
  
      {/* Observacions i enviament */}
      <div style={{ marginTop: '20px' }}>
        <label htmlFor="observations" style={{ display: 'block', marginBottom: '5px' }}>
          Observations (optional):
        </label>
        <textarea
          id="observations"
          value={observations}
          onChange={(e) => setObservations(e.target.value)}
          placeholder="Add any additional observations here..."
          style={{
            width: '100%',
            minHeight: '80px',
            padding: '8px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            textAlign: 'left',
          }}
        />
      </div>
      <button
        onClick={() => setShowConsentModal(true)}
        className="button-center"
        style={{ marginTop: '20px', width: '100%' }}
      >
        Submit
      </button>
  
      {/* Modal de consentiment */}
      <ConsentModal
        isOpen={showConsentModal}
        onRequestClose={() => setShowConsentModal(false)}
        onConsentChange={(isAccepted) => setConsentGiven(isAccepted)}
        onSubmit={handleSubmit}
      />
  
      {/* Feedback */}
      {showFeedback && (
        <>
          <YourCategorization categories={categories} observations={observations} />
          <h2>Other Users' Categorizations</h2>
          <Feedback feedbackData={otherUsersCategorizations} />
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <p className="centered-text">
              Page {currentPage} of {totalPages}*
            </p>
            <button
              className="button-center"
              onClick={() => {
                if (currentPage > 1) {
                  fetchFeedback(currentPage - 1);
                }
              }}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button
              className="button-center"
              onClick={() => {
                if (currentPage < totalPages) {
                  fetchFeedback(currentPage + 1);
                }
              }}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
            <p className="small">
              *If you shared your categorization, the last page will display yours.
            </p>
          </div>
        </>
      )}
    </div>
  );  
}

export default ConceptGrouping;