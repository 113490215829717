// Feedback.jsx

import React from 'react';

function Feedback({ feedbackData }) {
  if (!feedbackData || feedbackData.length === 0) {
    return (
      <div className="highlighted-section">
        <h3>No feedback available yet</h3>
        <p>Once more users submit their categorizations, you will see their results here.</p>
      </div>
    );
  }

  return (
    <div className="highlighted-section">
      {feedbackData.map((entry, index) => (
        <div key={index}>
          {entry.categories.map((category, catIndex) => (
            <div key={catIndex} style={{ marginBottom: '10px' }}>
              <strong>{category.categoryName}</strong>
              <div>
                {category.concepts.map((concept, conceptIndex) => (
                  <div key={conceptIndex} style={{ marginLeft: '10px' }}>
                    {concept.label}
                  </div>
                ))}
              </div>
            </div>
          ))}
          {entry.observations && (
            <div style={{ marginTop: '10px' }}>
              <strong>Observations:</strong>
              <p>{entry.observations}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default Feedback;