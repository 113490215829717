// CAPEVRVocalTasks.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';

function CAPEVRVocalTasks({ modeledSentences, setModeledSentences }) {
  const { t } = useTranslation();

  return (
    <div className="capevr-vocal-tasks">

      {/* Vowels */}
      <div className="capevr-task-section">
        <p><strong>{t('vowels')}:</strong></p>
        <p> {t('vowels_description')}</p>
      </div>

      {/* Sentences */}
      <div className="capevr-task-section">
        <h4>{t('sentences')}:</h4>
        <div className="capevr-sentences-columns">
          {/* Columna esquerra */}
          <ol className="capevr-sentences-column" type="a" start={1}>
            <li>{t('sentence_a')}</li>
            <li>{t('sentence_b')}</li>
            <li>{t('sentence_c')}</li>
            <li>{t('sentence_d')}</li>
          </ol>

          {/* Columna dreta */}
          <ol className="capevr-sentences-column" type="a" start={5}>
            <li data-letter="e">{t('sentence_e')}</li>
            <li data-letter="f">{t('sentence_f')}</li>
            <li data-letter="g">{t('sentence_g')}</li>
            {/* Aquí poses el checkbox sense numeració */}
            <li style={{ listStyleType: 'none', paddingLeft: '0' }}>
              <label className="capevr-checkbox-label">
                <input
                  type="checkbox"
                  name="modeledSentences"
                  value="modeledSentences"
                  checked={modeledSentences}
                  onChange={(e) => setModeledSentences(e.target.checked)}
                />
                {t('check_if_the_examiner_modeled_the_sentences')}
              </label>
            </li>
          </ol>
        </div>
      </div>

      {/* Extemporaneous Speech */}
      <div className="capevr-task-section">
        <p>
          <strong>{t('extemporaneous_speech')}:</strong> {t('extemporaneous_speech_description')}
        </p>
      </div>
    </div>
  );
}

export default CAPEVRVocalTasks;