// compatibilityRules.js
// TODO: Implement a familiarity-based system for guiding users in methodology selection
// Instead of strict compatibility/incompatibility rules, create a system that highlights the familiarity level of each option:
// 1. Define familiarity levels ('High', 'Medium', 'Low') for each option in the methodologyOptions object.
// 2. Highlight 'High' familiarity options with vibrant colors/icons, 'Medium' familiarity with neutral tones, and 'Low' familiarity with subdued tones.
// 3. Ensure all options remain selectable, but provide subtle guidance through visual cues and tooltips explaining the level of familiarity.
// 4. Add a progress bar or label to indicate the overall familiarity score of the selected combination, without enforcing restrictions.
// 5. Design this system to encourage exploration and creativity while providing clear guidance on common methodological practices.


const compatibilityRules = {
    Approach:{
        Quantitative: {
            Paradigm: ['Positivist'],
            TheoreticalPerspective: ['Post-Positivist'],
            Reasoning: ['Deductive'],
            StudyControlType: ['Experimental', 'Quasi-experimental', 'Observational'],
            Objective: [
                'Explanatory - Cause-Effect',
                'Predictive',
                'Comparative',
                'Analytical',
                'Developmental',
                'Instrument Development',
                'Descriptive',
                'Evaluative',  
                'Synthesis' 
                ],
            Design: [
                'Factorial Experiments',
                'Independent Group Comparison',
                'Dependent Group Comparison',
                'Cohort',
                'Case-Control',
                'Correlational Design',
                'Repeated Measures Design',
                'Single-Case Experimental Design',
                'Survey Design',
                'Time-Series Analysis',
                'Cross-Over Trials'
            ],
            DataCollectionTiming: ['Prospective', 'Longitudinal', 'Cross-sectional', 'Retrospective'],
            DataCollectionMethod: [
                'Tests',
                'Surveys',
                'Questionnaires',
                'Task-Based Assessments',
                'Behavioral Checklists',
                'Observations'
            ],
            DataType: [
                'Numeric Data',
                'Physiological Measures',
                'Geospatial Data'
            ],
            VariableType: [
                'Continuous (Ratio)',
                'Continuous (Interval)',
                'Ordinal'
            ],
            UnitOfAnalysis: ['Individuals', 'Groups'],
            Sampling: [
                'Random',
                'Stratified Random Sampling',
                'Probability',
                'Systematic',
                'Quota Sampling',
                'Purposive',
                'Criterion-based'
            ],
            ControlTechniques: [
                'Randomization',
                'Blinding',
                'Control Groups',
                'Counterbalancing',
                'Matching',
                'Repeated Measures',
                'Multiple Baseline Control',
                'Treatment Withdrawal',
                'Stratification',
                'Use of Benchmarks'
            ],
            QualityEnhancementStrategies: [
                'Pilot Testing',
                'Test-retest',
                'Inter-rater Reliability',
                'Simulation'
            ],
            DataAnalysisStrategies: [
                'Inferential Statistics',
                'Chi-square and Contingency Table Analysis',
                'Logistic Regression',
                'Multivariate Analysis',
                'Descriptive Statistics',
                'Survival Analysis',
                'Sequential Analysis',
                'Structural Equation Modeling (SEM)',
                'Latent Class Analysis',
                'Bayesian Analysis',
                'Cluster Analysis'
            ],
            EthicalConsiderations: [
                'Ethical Committee Approval',
                'Informed Consent'
            ]
        },
        Qualitative: {
            Paradigm: ['Interpretive'],
            TheoreticalPerspective: [
                'Constructivist',
                'Post-Structuralist',
                'Critical Theory',
                'Feminist Theory'
            ],
            Reasoning: ['Inductive'],
            StudyControlType: ['Observational'],
            Objective: [
                'Descriptive',
                'Evaluative',
                'Exploratory',
                'Synthesis',
                'Explanatory - Meaning',
                'Theory-Generation',
                'Case-Specific'
            ],
            Design: [
                'Case Study',
                'Phenomenology',
                'Grounded Theory',
                'Ethnography',
                'Narrative Inquiry'
            ],
            DataCollectionTiming: ['Cross-sectional', 'Retrospective'],
            DataCollectionMethod: [
                'Interviews',
                'Focus Groups',
                'Reflexive Journals',
                'Virtual Ethnography',
                'Document Analysis'
            ],
            DataType: [
                'Textual Data',
                'Audio/Video Data',
                'Images',
                'Social Media Data'
            ],
            VariableType: ['Nominal', 'Ordinal'],
            UnitOfAnalysis: [
                'Documents/Artifacts',
                'Institutions',
                'Groups',
                'Individuals'
            ],
            Sampling: [
                'Non-probability',
                'Purposive',
                'Snowball',
                'Theoretical Sampling',
                'Saturation-based',
                'Convenience'
            ],
            ControlTechniques: ['Historical Control'],
            QualityEnhancementStrategies: [
                'Triangulation',
                'Iterative Refinement',
                'Constant Comparison',
                'Thematic Saturation',
                'Participatory Feedback Loops'
            ],
            DataAnalysisStrategies: [
                'Content Analysis',
                'Thematic Analysis',
                'Grounded Theory Coding',
                'Open Coding',
                'Axial Coding',
                'Selective Coding',
                'Phenomenological Analysis',
                'Narrative Analysis',
                'Case-Specific Analysis',
                'Cross-Case Analysis'
            ],
            EthicalConsiderations: [
                'Informed Consent',
                'Anonymization'
            ]
        },
        Mixed: {
            Paradigm: ['Pragmatic'],
            TheoreticalPerspective: [
                'Post-Positivist',
                'Constructivist',
                'Post-Structuralist',
                'Critical Theory',
                'Feminist Theory'
            ],
            Reasoning: ['Deductive', 'Inductive'],
            StudyControlType: ['Experimental', 'Quasi-experimental', 'Observational'],
            Objective: [
                'Analytical',
                'Developmental',
                'Instrument Development',
                'Descriptive',
                'Evaluative',
                'Exploratory',
                'Synthesis',
                'Explanatory - Meaning',
                'Explanatory - Cause-Effect'
            ],
            Design: [
                'Exploratory Sequential Mixed Methods',
                'Explanatory Sequential Mixed Methods',
                'Systematic Review',
                'Meta-analysis',
                'Integrative Review',
                'Scoping Review',
                'Narrative Review',
                'Action Research',
                'Phenomenology',
                'Case Study',
                'Survey Design'
            ],
            DataCollectionTiming: [
                'Prospective',
                'Longitudinal',
                'Cross-sectional',
                'Retrospective'
            ],
            DataCollectionMethod: [
                'Surveys',
                'Questionnaires',
                'Tests',
                'Interviews',
                'Focus Groups',
                'Document Analysis',
                'Observations'
            ],
            DataType: [
                'Numeric Data',
                'Physiological Measures',
                'Geospatial Data',
                'Social Media Data',
                'Images',
                'Audio/Video Data',
                'Textual Data'
            ],
            VariableType: [
                'Continuous (Ratio)',
                'Continuous (Interval)',
                'Ordinal',
                'Nominal'
            ],
            UnitOfAnalysis: [
                'Individuals',
                'Groups',
                'Institutions',
                'Documents/Artifacts'
            ],
            Sampling: [
                'Random',
                'Stratified Random Sampling',
                'Probability',
                'Systematic',
                'Comprehensive',
                'Purposive',
                'Criterion-based',
                'Snowball',
                'Theoretical Sampling',
                'Convenience'
            ],
            ControlTechniques: [
                'Randomization',
                'Control Groups',
                'Matching',
                'Stratification',
                'Use of Benchmarks',
                'Historical Control'
            ],
            QualityEnhancementStrategies: [
                'Pilot Testing',
                'Inter-rater Reliability',
                'Test-retest',
                'Triangulation',
                'Adaptive Sampling',
                'Sequential Exploratory Strategy',
                'Iterative Refinement',
                'Constant Comparison',
                'Thematic Saturation',
                'Participatory Feedback Loops'
            ],
            DataAnalysisStrategies: [
                'Inferential Statistics',
                'Chi-square and Contingency Table Analysis',
                'Logistic Regression',
                'Correspondence Analysis',
                'Multivariate Analysis',
                'Descriptive Statistics',
                'Mixed Methods Integration',
                'Visual Analytics',
                'Sentiment Analysis',
                'Content Analysis',
                'Thematic Analysis',
                'Grounded Theory Coding',
                'Phenomenological Analysis',
                'Narrative Analysis',
                'Case-Specific Analysis',
                'Cross-Case Analysis'
            ],
            EthicalConsiderations: [
                'Ethical Committee Approval',
                'Informed Consent',
                'Anonymization'
            ]
        }
    }
};

export default compatibilityRules;