import React from 'react';
import definitions from './definitions';
import methodologyOptions from './methodologyOptions';

function SelectedOptionsSummary({ selectedOptions }) {
    const sortedCategories = Object.keys(selectedOptions).sort(
        (a, b) => Object.keys(methodologyOptions).indexOf(a) - Object.keys(methodologyOptions).indexOf(b)
    );

    // Funció per generar el text del resum
    const generateSummaryText = () => {
        return sortedCategories.map((category) => {
            const values = Array.isArray(selectedOptions[category])
                ? selectedOptions[category]
                : [selectedOptions[category]];

            return `${category}: ${values.map(option => `${option}: ${definitions[option] || 'No definition available'}`).join(', ')}`;
        }).join('\n');
    };

    // Funció per copiar el resum al porta-retalls
    const copyToClipboard = () => {
        const summaryText = generateSummaryText();
        navigator.clipboard.writeText(summaryText)
            .then(() => alert('Copied to clipboard!'))
            .catch(err => console.error('Failed to copy text: ', err));
    };

    return (
        <div className="highlighted-section" style={{ marginTop: '20px' }}>
            <h2>Selected Options</h2>
            {sortedCategories.map((category) => {
                const values = Array.isArray(selectedOptions[category])
                    ? selectedOptions[category]
                    : [selectedOptions[category]];

                return (
                    <div key={category} style={{ marginBottom: '20px' }}>
                        <strong style={{ color: '#209385', fontWeight: 'bold' }}>{category}:</strong>
                        <ul style={{ paddingLeft: '20px', listStyle: 'none', margin: '10px 0' }}>
                            {values.map((option, index) => (
                                <li key={index} style={{ marginBottom: '10px', fontWeight: 'bold' }}> {/* Incrementa la separació entre elements */}
                                    {option}: <span style={{ fontStyle: 'italic', fontWeight: 'normal' }}>
                                        {definitions[option] || 'No definition available'}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                );
            })}
            <button className="button-center" onClick={copyToClipboard} style={{ marginTop: '20px' }}>Copy Summary to Clipboard</button>
        </div>
    );
}

export default SelectedOptionsSummary;