import React, { useState } from "react";
import AttributesCustomization from "./AttributesCustomization";
import ScaleCustomization from "./ScaleCustomization";
import CustomizedResource from "./CustomizedResource";
import { useTranslation } from "react-i18next";

const ResourceBuilder = () => {
  const { t } = useTranslation();

  const [view, setView] = useState("sampleSelection"); // Vista inicial
  const [samplePreference, setSamplePreference] = useState(""); // Preferència de mostra
  const [selectedAttrs, setSelectedAttrs] = useState([]);
  const [scales, setScales] = useState([]);
  const [error, setError] = useState(false); // Estat per gestionar l'error

  const handleSamplePreferenceChange = (value) => {
    setSamplePreference(value);
    setError(false); // Elimina l'error si es selecciona una opció
  };

  const handleNext = () => {
    if (!samplePreference) {
      setError(true); // Mostra l'error si no s'ha seleccionat cap opció
    } else {
      setError(false); // Amaga l'error si tot està correcte
      setView("attributes");
    }
  };

  return (
    <div style={{ padding: "20px", width: "100%", margin: "0" }}>
      <h1 style={{ textAlign: "center" }}>{t("resource_builder")}</h1>

      {view === "sampleSelection" && (
        <div style={{ textAlign: "center" }}>
          <h2>{t("choose_sample_mode")}</h2>

          <div style={{ marginBottom: "20px" }}>
            <select
              value={samplePreference}
              onChange={(e) => handleSamplePreferenceChange(e.target.value)}
              className="sample-selector"
              style={{
                padding: "10px",
                fontSize: "16px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            >
              <option value="">{t("select_your_sample_preference")}</option>
              <option value="random">{t("random_sample")}</option>
              <option value="manual">{t("manual_sample_selection")}</option>
              <option value="upload">{t("upload_sample")}</option>
            </select>
          </div>

          {error && (
            <p style={{ color: "red", marginTop: "10px" }}>
              {t("error_select_sample_mode")}
            </p>
          )}

          <button
            onClick={handleNext}
            className="button-center"
            style={{ marginTop: "20px" }}
          >
            {t("next_to_attribute_selection")}
          </button>
        </div>
      )}

      {view === "attributes" && (
        <AttributesCustomization
          selectedAttributes={selectedAttrs}
          setSelectedAttributes={setSelectedAttrs}
          onProceed={() => setView("scales")}
          onBack={() => setView("sampleSelection")}
        />
      )}

      {view === "scales" && (
        <ScaleCustomization
          selectedAttrs={selectedAttrs}
          scales={scales}
          setScales={setScales}
          onBack={() => setView("attributes")}
          onProceed={() => setView("customizedResource")}
        />
      )}

      {view === "customizedResource" && (
        <CustomizedResource
          selectedAttributes={selectedAttrs}
          scales={scales}
          samplePreference={samplePreference}
          onBack={() => setView("scales")}
        />
      )}
    </div>
  );
};

export default ResourceBuilder;