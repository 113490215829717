// AdditionalFeaturesEvaluation.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';

function AdditionalFeaturesEvaluation({
  selectedAdditionalFeatures,
  setSelectedAdditionalFeatures,
  additionalFeaturesValues,
  setAdditionalFeaturesValues, // Afegit
  otherAdditionalFeatureTag,
  setOtherAdditionalFeatureTag,
}) {
  const { t } = useTranslation();

  const handleCheckboxChange = (feature) => {
    if (selectedAdditionalFeatures.includes(feature)) {
      // Deseleccionar
      setSelectedAdditionalFeatures(
        selectedAdditionalFeatures.filter((item) => item !== feature)
      );
      const updatedValues = { ...additionalFeaturesValues };
      delete updatedValues[feature]; // Esborra el valor
      setAdditionalFeaturesValues(updatedValues);
      if (feature === 'otherAdditionalFeature') {
        setOtherAdditionalFeatureTag('');
      }
    } else {
      // Seleccionar
      setSelectedAdditionalFeatures([...selectedAdditionalFeatures, feature]);
      setAdditionalFeaturesValues({
        ...additionalFeaturesValues,
        [feature]: 0, // Valor inicial
      });
    }
  };

  return (
    <div className="capevr-radio-group">
      <label className="capevr-radio-label">{t('additional_features')}</label>
      <div className="capevr-checkboxes-horizontal">
        {[
          { value: 'aphonia', translationKey: 'aphonia' },
          { value: 'asthenia', translationKey: 'asthenia' },
          { value: 'diplophonia', translationKey: 'diplophonia' },
          { value: 'falsetto', translationKey: 'falsetto' },
          { value: 'fry', translationKey: 'fry' },
          { value: 'hardGlottalAttack', translationKey: 'hard_glottal_attack' },
          { value: 'wetGurgly', translationKey: 'wet_gurgly' },
          { value: 'otherAdditionalFeature', translationKey: 'other' },
        ].map(({ value, translationKey }) => (
          <label key={value} className="capevr-checkbox-label-inline">
            <input
              type="checkbox"
              name={value}
              value={value}
              checked={selectedAdditionalFeatures.includes(value)}
              onChange={() => handleCheckboxChange(value)}
            />
            {t(translationKey)}
            {value === 'otherAdditionalFeature' &&
              selectedAdditionalFeatures.includes(value) && (
                <input
                  type="text"
                  id="otherAdditionalFeatureTag"
                  value={otherAdditionalFeatureTag}
                  onChange={(e) => setOtherAdditionalFeatureTag(e.target.value)}
                  placeholder={t('specify')}
                  className="capevr-input-inline"
                />
              )}
          </label>
        ))}
      </div>
    </div>
  );
}

export default AdditionalFeaturesEvaluation;