// OtherVocalAttributesRating.jsx

import React from 'react';
import VocalAttributeRatingInput from './VocalAttributeRatingInput';
import { useTranslation } from 'react-i18next';

function OtherVocalAttributesRating({
  selectedInstabilities,
  instabilitiesValues,
  setInstabilitiesValues,
  selectedAdditionalFeatures,
  additionalFeaturesValues,
  setAdditionalFeaturesValues,
  otherInstabilityTag,
  otherAdditionalFeatureTag,
}) {
  const { t } = useTranslation();

  // Map de claus traduïbles
  const translationMap = {
    aphonicBreak: 'aphonic_break',
    pitchBreak: 'pitch_break',
    pitchInstability: 'pitch_instability',
    spasm: 'spasm',
    tremor: 'tremor',
    otherInstability: 'other',
    aphonia: 'aphonia',
    asthenia: 'asthenia',
    diplophonia: 'diplophonia',
    falsetto: 'falsetto',
    fry: 'fry',
    hardGlottalAttack: 'hard_glottal_attack',
    wetGurgly: 'wet_gurgly',
    otherAdditionalFeature: 'other',
  };

  const getTranslatedLabel = (key, tag) => {
    if ((key === 'otherInstability' || key === 'otherAdditionalFeature') && tag) {
      return tag; // Mostra el tag personalitzat si hi ha
    }
    return t(translationMap[key] || key); // Busca la clau traduïda
  };

  const handleSliderChange = (key, value, type) => {
    if (type === 'instability') {
      setInstabilitiesValues({
        ...instabilitiesValues,
        [key]: value,
      });
    } else if (type === 'feature') {
      setAdditionalFeaturesValues({
        ...additionalFeaturesValues,
        [key]: value,
      });
    }
  };

  // Comprova si hi ha sliders visibles
  const hasVisibleSliders = selectedInstabilities.length > 0 || selectedAdditionalFeatures.length > 0;

  return (
    <div className="capevr-rating">
      {/* Missatge per a l'usuari si no hi ha sliders visibles */}
      {!hasVisibleSliders && (
        <div className="capevr-info-message">
          {t('select_to_rate')}
        </div>
      )}

      {/* Sliders per a instabilities seleccionades */}
      {selectedInstabilities.map((instability) => (
        <VocalAttributeRatingInput
          key={instability}
          label={getTranslatedLabel(instability, otherInstabilityTag)}
          value={instabilitiesValues[instability] || 0}
          setValue={(value) => handleSliderChange(instability, value, 'instability')}
          index={instability}
        />
      ))}

      {/* Sliders per a additional features seleccionades */}
      {selectedAdditionalFeatures.map((feature) => (
        <VocalAttributeRatingInput
          key={feature}
          label={getTranslatedLabel(feature, otherAdditionalFeatureTag)}
          value={additionalFeaturesValues[feature] || 0}
          setValue={(value) => handleSliderChange(feature, value, 'feature')}
          index={feature}
        />
      ))}
    </div>
  );
}

export default OtherVocalAttributesRating;