// CAPEVRScholar.jsx
// TODO Integrar CAPEVRForm aquí

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CAPEVRForm from './cape-vr-shared-files/CAPEVRForm';
import RatingDetails from './cape-vr-shared-files/RatingDetails';
import { formatResultsForCSV, formatResultsForReport } from './cape-vr-shared-files/formatResultsForClipboard'; 
import AudioUploader from '../shared-files/tools-and-functions/AudioUploader';
import NavigationIcons from './../views/app-views/NavigationIcons';

function CAPEVRScholar() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    sampleName: '',
    overallSeverity: { value: 0, variability: 'consistent' },
    roughness: { value: 0, variability: 'consistent' },
    breathiness: { value: 0, variability: 'consistent' },
    strain: { value: 0, variability: 'consistent' },
    pitch: { value: 0, variability: 'consistent', direction: { tooHigh: false, tooLow: false } },
    loudness: { value: 0, variability: 'consistent', direction: { tooLoud: false, tooSoft: false } },
    additionalResonances: { resonances: {} },
    additionalFeatures: { features: {} },
    comments: ''
  });

  const [resultsVisible, setResultsVisible] = useState(false);

  const handleFileUpload = (uploadedFile) => {
    const sampleNameFromUpload = uploadedFile.name;
    setFormData(prevFormData => ({
      ...prevFormData,
      sampleName: sampleNameFromUpload.replace(/\.[^/.]+$/, "")
    }));
  };  

  const handleSubmit = (data) => {
    setFormData(prevFormData => ({
        ...prevFormData, 
        ...data  
    }));
    setResultsVisible(true);  
    window.scrollTo(0, 0); 
  };

  const handleCopyToClipboard = (format) => {
    let resultsString = '';
    if (format === 'csv') {
      resultsString = formatResultsForCSV(formData, t);
    } else if (format === 'report') {
      const formattedData = formatResultsForReport(formData, t);
      const lines = [
        `${t('sample')}: ${formData.sampleName}`,
        `${t('overall_severity')}: ${formattedData.overallSeverity}`,
        `${t('roughness')}: ${formattedData.roughness}`,
        `${t('breathiness')}: ${formattedData.breathiness}`,
        `${t('strain')}: ${formattedData.strain}`,
        `${t('pitch')}: ${formattedData.pitch}`,
        `${t('loudness')}: ${formattedData.loudness}`,
        `${t('comments_resonance')}: ${formattedData.additionalResonancesResults}`,
        `${t('additional_features')}: ${formattedData.additionalFeaturesResults}`,
        `${t('general_comments')}: ${formattedData.comments}`
      ];
      resultsString = lines.join('\n');
    }
    navigator.clipboard.writeText(resultsString)
      .then(() => {
        alert(t("ratings_copied_to_clipboard"));
      })
      .catch(err => {
        console.error(t("error_copying_to_clipboard"), err);
      });
  };  

  const handleRestart = () => {
    setResultsVisible(false);
    setFormData({
      sampleName: '',
      overallSeverity: { value: 0, variability: 'consistent' },
      roughness: { value: 0, variability: 'consistent' },
      breathiness: { value: 0, variability: 'consistent' },
      strain: { value: 0, variability: 'consistent' },
      pitch: { value: 0, variability: 'consistent', direction: { tooHigh: false, tooLow: false } },
      loudness: { value: 0, variability: 'consistent', direction: { tooLoud: false, tooSoft: false } },
      additionalResonances: { resonances: {} },
      additionalFeatures: { features: {} },
      comments: ''
    });
    window.scrollTo(0, 0);
  };

  const downloadCSV = (data) => {
    const csvString = formatResultsForCSV(data, t);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "results.csv");
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <h1>{t('cape_vr_scholar_title')}</h1>
      {!resultsVisible && (
        <>
          <p>{t('cape_vr_scholar_specific_instructions')}</p>
          <p>{t('lorem_ipsum')}</p>
        </>
      )}
      <div className="audio-uploader-center">
        <AudioUploader onFileUpload={handleFileUpload} />
      </div>
      {resultsVisible && (
        <p>{t('cape_vr_scholar_ratings_introduction')}</p>
      )}
      {!resultsVisible && (
        <>
          <br/>
          <h2>{t('cape_vr')}</h2>
          <CAPEVRForm onSubmit={handleSubmit} />
        </>
      )}
      {resultsVisible && (
        <div>
          <h2>{t('your_ratings')}</h2>
          <RatingDetails results={formData} />
        </div>
      )}
      {resultsVisible && (
        <div className="button-container">
          <h3>{t('download')}</h3>
          <button onClick={() => downloadCSV(formData)} className="button-center">{t('download_ratings_in_csv_format')}</button>
          <h3>{t('copy_ratings_to_clipboard')}</h3>
          <button onClick={() => handleCopyToClipboard('csv')} className="button-center">{t('copy_ratings_csv')}</button>
          <button onClick={() => handleCopyToClipboard('report')} className="button-center">{t('copy_ratings_report')}</button>
          <h3>{t('restart')}</h3>
          <button onClick={handleRestart} className="button-center">{t('evaluate_another_sample')}</button>
        </div>
      )}
      <br />
      <br />
      <div className="navigation-container">
          <NavigationIcons />
        </div>
    </div>
  );
}

export default CAPEVRScholar;