// CategoryTable.jsx

import React from 'react';

function CategoryTable({ 
    sortedVisibleCategories, 
    methodologyOptions, 
    selectedOptions, 
    disabledOptions, 
    toggleOption 
}) {
    return (
        <table className="table" style={{ width: '100%' }}>
            <colgroup>
                <col style={{ width: '20%' }} />
                <col style={{ width: '80%' }} />
            </colgroup>
            <thead>
                <tr>
                    <th>Category</th>
                    <th>Options</th>
                </tr>
            </thead>
            <tbody>
                {sortedVisibleCategories.map((category) => (
                    <tr key={category}>
                        <td style={{ fontWeight: 'bold', textAlign: 'center' }}>{category}</td>
                        <td style={{ textAlign: 'center' }}>
                            {methodologyOptions[category].map((option) => {
                                const isSelected = selectedOptions[category]?.includes(option);
                                const isDisabled = disabledOptions[category]?.includes(option);

                                return (
                                    <button
                                        key={option}
                                        disabled={isDisabled}
                                        className={`button-selectable ${isSelected ? 'selected' : ''}`}
                                        style={{
                                            backgroundColor: isDisabled ? '#e0e0e0' : '',
                                            color: isDisabled ? '#999' : '',
                                            cursor: isDisabled ? 'not-allowed' : 'pointer',
                                            borderColor: isDisabled ? '#b3b3b3' : ''
                                        }}
                                        onClick={() => !isDisabled && toggleOption(category, option)}
                                    >
                                        {option}
                                    </button>
                                );
                            })}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default CategoryTable;
