// ResourcesForTraining.jsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './../../locales/i18n';
import { useAuth } from './../../auth-and-backend/auth/authContext';
import LoginModal from './../../auth-and-backend/modals/LoginModal';
import SignUpModal from './../../auth-and-backend/modals/SignUpModal';
import NavigationIcons from './../app-views/NavigationIcons';

function ResourcesForTraining() {
    const { t } = useTranslation();
    const { user } = useAuth();
    const navigate = useNavigate();
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);

    function handleRestrictedButtonClick(targetRoute) {
        if (!user) {
            setIsLoginModalOpen(true);
        } else {
            navigate(targetRoute);
            console.log("User is logged in, navigate to the restricted area");
        }
    }

    const renderLockIcon = () => {
        return (
            user ? (
                <i className="fas fa-lock-open" style={{ color: '#a5d3ce' }}></i>
            ) : (
                <i className="fas fa-lock" style={{ color: 'red' }}></i>
            )
        );
    };

    return (
        <div className="main-content">
            <h1>{t('resources_for_autonomous_training_title')}</h1>
            <p>{t('resources_for_autonomous_training_presentation')}</p>

            <p className="centered-text">{t('practice_evaluating_random_samples_at_your_own_pace')}:</p>
            <button
                className="button-link restricted-link"
                onClick={() => handleRestrictedButtonClick('/cape-v-training')}
            >
                {t('cape_v_training_title')}
                <span className="restricted-marker">{renderLockIcon(true)}</span>
            </button><br/>


            {/* OS and RBS Training */}
            <button className="button-link" onClick={() => navigate('/os-training')}>{t('overall_severity')} (0-3 & 0-100)<span className="new-indicator">{t('new')}</span></button><br/>
            <button className="button-link" onClick={() => navigate('/rbs-training')}>
                {t('roughness_breathiness_strain')}
            </button><br/>


            {/* Guess the Diagnosis */}
            <p className="centered-text">{t('play_guessing_the_vocal_diagnosis_from_random_samples')}:</p>
            <button
                className="button-link restricted-link"
                onClick={() => handleRestrictedButtonClick('/guess-the-diagnosis')}
            >
                {t('guess_the_diagnosis_title')}
                <span className="restricted-marker">{renderLockIcon(true)}</span>
            </button><br/><br/><br/>

            <p className="centered-text">{t('turn_your_practice_into_research_power')}:</p>
            <button className="navigation-button" onClick={() => navigate('/research-at-all-voiced')}>
                {t('research_at_allvoiced')}
            </button><br/>

            <div className="navigation-container">
                <NavigationIcons exclude={['/resources-for-autonomous-training']} />
            </div>

            <LoginModal
                isOpen={isLoginModalOpen}
                onRequestClose={() => setIsLoginModalOpen(false)}
                openSignUpModal={() => { setIsLoginModalOpen(false); setIsSignUpModalOpen(true); }}
            />
            <SignUpModal
                isOpen={isSignUpModalOpen}
                onRequestClose={() => setIsSignUpModalOpen(false)}
                openLoginModal={() => { setIsSignUpModalOpen(false); setIsLoginModalOpen(true); }}
            />
        </div>
    );
}

export default ResourcesForTraining;