// Demographics.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';

function Demographics({
  clientId,
  setClientId,
  gender,
  setGender,
  age,
  setAge,
  examiner,
  setExaminer,
  recordingDate,
  setRecordingDate,
}) {
  const { t } = useTranslation();

  return (
    <div className="demographics">
      <h2>{t('demographics')}</h2>
      <div className="demographics-grid">
        {/* Client ID */}
        <div className="demographics-item">
          <label htmlFor="clientId">{t('client_id')}</label>
          <input
            type="text"
            id="clientId"
            value={clientId}
            onChange={(e) => setClientId(e.target.value)}
            className="demographics-input"
          />
        </div>

        {/* Gender */}
        <div className="demographics-item">
          <label htmlFor="gender">{t('gender')}</label>
          <input
            type="text"
            id="gender"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            className="demographics-input"
          />
        </div>

        {/* Age */}
        <div className="demographics-item">
          <label htmlFor="age">{t('age')}</label>
          <input
            type="number"
            id="age"
            value={age}
            onChange={(e) => setAge(e.target.value)}
            className="demographics-input"
          />
        </div>

        {/* Examiner */}
        <div className="demographics-item">
          <label htmlFor="examiner">{t('examiner')}</label>
          <input
            type="text"
            id="examiner"
            value={examiner}
            onChange={(e) => setExaminer(e.target.value)}
            className="demographics-input"
          />
        </div>

        {/* Date */}
        <div className="demographics-item">
          <label htmlFor="recordingDate">{t('date')}</label>
          <input
            type="date"
            id="recordingDate"
            value={recordingDate}
            onChange={(e) => setRecordingDate(e.target.value)}
            className="demographics-input"
          />
        </div>
      </div>
    </div>
  );
}

export default Demographics;