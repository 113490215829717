// userUtils.js

import axios from 'axios';

/**
 * Calcula l'edat a partir de la data de naixement.
 * @param {string} birthDate - Data de naixement (en format ISO).
 * @returns {number} - L'edat calculada.
 */
export const calculateAge = (birthDate) => {
  const birth = new Date(birthDate); // Converteix la data de naixement a un objecte Date.
  const today = new Date(); // Data actual.
  let age = today.getFullYear() - birth.getFullYear(); // Calcula la diferència d'anys.
  
  // Ajusta si el mes/dia actual és abans del mes/dia de naixement.
  if (
    today.getMonth() < birth.getMonth() ||
    (today.getMonth() === birth.getMonth() && today.getDate() < birth.getDate())
  ) {
    age--;
  }
  return age;
};

/**
 * Recupera les dades de l'usuari autenticat.
 * @param {string} apiUrl - La URL base de l'API.
 * @returns {Promise<object>} - Les dades de l'usuari o un error si no s'ha pogut recuperar.
 */
export const fetchUserData = async (apiUrl) => {
  const token = localStorage.getItem('token'); // Llegeix el token d'autorització del `localStorage`.
  if (!token) {
    throw new Error('Authorization token not found'); // Llança un error si no hi ha token.
  }
  
  try {
    // Fa la sol·licitud GET a l'endpoint `/users/me` amb el token al header.
    const response = await axios.get(`${apiUrl}/users/me`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const user = response.data; // Dades de l'usuari retornades per l'API.
    
    // Retorna un objecte amb les dades processades.
    return {
      id: user._id,
      age: calculateAge(user.dateOfBirth), // Calcula l'edat a partir de la data de naixement.
      pronoun: user.pronoun,
      countryOfResidence: user.countryOfResidence,
      communicationLanguage: user.communicationLanguage,
      profession: user.profession,
      voiceCompetenceLevel: user.voiceCompetenceLevel,
    };
  } catch (error) {
    console.error('Error fetching user data:', error); // Mostra l'error al log.
    throw error; // Torna a llançar l'error perquè pugui ser gestionat més amunt.
  }
};