// dadesMostresTaller.js

// Posar aquí les mostres que es vulguin fer servir per un taller concret.

const dadesMostresTaller = [
    
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V004","sampleName": "V004","sampleLink": "samples/thesis/concatenated/spanish/V004.wav","age": 58,"gender": "F","reasonForConsultation": "Nódulo cuerda vocal. Me iban a operar pero a través de logopedia se ha reducido. Me haciendo controles y voy haciendo logopedia.","diagnose": "Nòduls vocals","useSpokenVoice": "No professional ni semiprofessional.","useSingingVoice": "No professional ni semiprofessional (en família, amb els amics, a la dutxa, al cotxe, etc.)","vhi10": "5","language": "spanish","overallSeverity": [[57,43]],"roughness": [[40,34]],"breathiness": [[60,37]],"strain": [[20,26]],"pitch": [[0,18]],"loudness": [[40,15]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V006","sampleName": "V006","sampleLink": "samples/thesis/concatenated/spanish/V006.wav","age": 74,"gender": "F","reasonForConsultation": "Tengo muchos problemas porque me quedo afónica. Esto ya me viene de años.","diagnose": "Hiatus vocal. Faringitis crònica","useSpokenVoice": "No professional ni semiprofessional.","useSingingVoice": "No canto mai","vhi10": "20","language": "spanish","overallSeverity": [[40,42,57,75,35,18,73,34,67,19,40,28,25,11,27,28]],"roughness": [[25,51,57,80,54,21,79,60,70,27,70,28,54,18,43,29]],"breathiness": [[10,22,10,60,12,20,53,26,50,4,22,17,29,0,37,10]],"strain": [[20,10,0,70,0,19,13,23,17,8,21,23,4,23,0]],"pitch": [[0,19,50,85,18,57,0,0,6,0,20,0,0,0,10,0]],"loudness": [[0,0,65,80,0,58,45,0,7,0,13,0,0,7,0,26]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V009","sampleName": "V009","sampleLink": "samples/thesis/concatenated/spanish/V009.wav","age": 56,"gender": "M","reasonForConsultation": "Vengo porque tengo pólipos y en el trabajo necesito la voz.","diagnose": "Pòlip","useSpokenVoice": "Professional (entrenador/a, monitor/a, operador/a, actor o actriu, etc.).","useSingingVoice": "No professional ni semiprofessional (en família, amb els amics, a la dutxa, al cotxe, etc.)","vhi10": "22","language": "spanish","overallSeverity": [[60,21,56,50,22,71,70,45,58,40,61,54,49,8,26,14]],"roughness": [[40,26,58,61,32,74,69,57,68,48,66,52,42,12,37,21]],"breathiness": [[45,20,0,40,23,24,59,35,41,42,58,11,15,0,3,0]],"strain": [[55,19,6,60,19,69,70,35,30,26,47,57,40,8,31,20]],"pitch": [[0,18,28,0,0,50,23,28,20,0,17,0,37,7,0,8]],"loudness": [[35,4,28,70,0,12,61,30,7,10,34,13,22,0,11,20]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V010","sampleName": "V010","sampleLink": "samples/thesis/concatenated/spanish/V010.wav","age": 68,"gender": "M","reasonForConsultation": "Ve per disfàgia. Yo he tenido 4 ictus en un año. De estos ictus tengo una disfagia. Ahora aún se me entiende algo pero hace poco no se me entendía absolutamente nada.","diagnose": "Disàrtria. Pòlip (postquirúrgic). Disfàgia","useSpokenVoice": "No professional ni semiprofessional.","useSingingVoice": "No canto mai","vhi10": "12","language": "spanish","overallSeverity": [[97,92]],"roughness": [[100,92]],"breathiness": [[95,92]],"strain": [[90,20]],"pitch": [[80,66]],"loudness": [[0,0]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V015","sampleName": "V015","sampleLink": "samples/thesis/concatenated/spanish/V015.wav","age": 76,"gender": "F","reasonForConsultation": "Va a logopèdia pel problema de veu perquè no pot parlar de pressa ni menjar de pressa. Paràlisi de CVE deguda a una cirurgia de pulmó","diagnose": "Paràlisi laríngia","useSpokenVoice": "No professional ni semiprofessional.","useSingingVoice": "No canto mai","vhi10": "27","language": "spanish","overallSeverity": [[67,35]],"roughness": [[45,29]],"breathiness": [[57,27]],"strain": [[10,67]],"pitch": [[60,17]],"loudness": [[32,23]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V016","sampleName": "V016","sampleLink": "samples/thesis/concatenated/spanish/V016.wav","age": 43,"gender": "F","reasonForConsultation": "De pronto me pongo ronca por reflujo.","diagnose": "Reflux gatroesofàgic","useSpokenVoice": "No professional ni semiprofessional.","useSingingVoice": "Semiprofessional (cors, cantant aficionat)","vhi10": "17","language": "spanish","overallSeverity": [[0,3]],"roughness": [[0,3]],"breathiness": [[0,2]],"strain": [[0,0]],"pitch": [[0,0]],"loudness": [[40,0]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V021","sampleName": "V021","sampleLink": "samples/thesis/concatenated/spanish/V021.wav","age": 47,"gender": "F","reasonForConsultation": "Disfonia de llarga durada amb molèsties. Treballo de cara al públic i perdo molt la veu.","diagnose": "Disfonia funcional","useSpokenVoice": "Professional (entrenador/a, monitor/a, operador/a, actor o actriu, etc.).","useSingingVoice": "No professional ni semiprofessional (en família, amb els amics, a la dutxa, al cotxe, etc.)","vhi10": "26","language": "spanish","overallSeverity": [[10,12]],"roughness": [[0,15]],"breathiness": [[10,9]],"strain": [[0,10]],"pitch": [[0,0]],"loudness": [[0,8]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V022","sampleName": "V022","sampleLink": "samples/thesis/concatenated/spanish/V022.wav","age": 53,"gender": "M","reasonForConsultation": "Me quedo sin voz por estrés y por hablar mucho al cabo de la semana. Tengo un nódulo en un lado y cuando me esfuerzo mucho me quedo sin voz en el trabajo. Siempre el sábado no tengo voz. Me voy recuperando poco a poco.","diagnose": "Nòduls vocals","useSpokenVoice": "Professional (entrenador/a, monitor/a, operador/a, actor o actriu, etc.).","useSingingVoice": "No canto mai","vhi10": "30","language": "spanish","overallSeverity": [[25,15]],"roughness": [[28,15]],"breathiness": [[15,21]],"strain": [[20,8]],"pitch": [[0,0]],"loudness": [[30,0]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V024","sampleName": "V024","sampleLink": "samples/thesis/concatenated/spanish/V024.wav","age": 59,"gender": "F","reasonForConsultation": "Tenía afonías y se me iba la voz y el ORL me mandó al logopeda","diagnose": "Disfonia funcional","useSpokenVoice": "No professional ni semiprofessional.","useSingingVoice": "No canto mai","vhi10": "26","language": "spanish","overallSeverity": [[50,54]],"roughness": [[50,36]],"breathiness": [[62,49]],"strain": [[30,12]],"pitch": [[0,26]],"loudness": [[10,9]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V027","sampleName": "V027","sampleLink": "samples/thesis/concatenated/spanish/V027.wav","age": 65,"gender": "F","reasonForConsultation": "Cuerdas vocales atrofiadas. Tenía disfonía continuada. Me quedo sin voz. Tengo las cuerdas vocales endurecidas.","diagnose": "Atròfia de plecs vocals","useSpokenVoice": "No professional ni semiprofessional.","useSingingVoice": "No canto mai","vhi10": "29","language": "spanish","overallSeverity": [[40,47]],"roughness": [[20,36]],"breathiness": [[40,43]],"strain": [[35,32]],"pitch": [[30,16]],"loudness": [[30,14]],},
    ];
    
    export default dadesMostresTaller;