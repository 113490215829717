// YouTubeAudioPlayer.jsx

import React, { useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

Modal.setAppElement('#root');

const CookieBannerModal = ({ isOpen, onAccept, onReject }) => {
  const { t } = useTranslation();

  const handleAccept = () => {
    onAccept();
  };

  const handleReject = () => {
    onReject();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {}} 
      contentLabel="Cookie Consent Modal"
      shouldCloseOnOverlayClick={false} 
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '600px',
          minWidth: '300px',
          padding: '20px',
        },
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <p style={{ marginBottom: '20px' }}>
            {t('youtube_cookies_banner_message')}
        </p>
        <button
          onClick={handleAccept}
          className="button-center"
        >
          {t('accept_cookies')}
        </button>
        <button
          onClick={handleReject}
          className="button-important"
        >
          {t('reject_cookies')}
        </button>
      </div>
    </Modal>
  );
};

const YouTubePage = ({ audioSamples }) => {
  const { t } = useTranslation();
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const [cookiesRejected, setCookiesRejected] = useState(false);
  const [videoId, setVideoId] = useState(null);

  const getRandomVideo = () => {
    if (!audioSamples || audioSamples.length === 0) {
      console.error('L\'array audioSamples és buit o no definit.');
      return null;
    }
    return audioSamples[Math.floor(Math.random() * audioSamples.length)];
  };

  const handleAcceptCookies = () => {
    const randomVideo = getRandomVideo();
    if (randomVideo) {
      setVideoId(randomVideo);
      setCookiesAccepted(true);
    }
  };

  if (cookiesRejected) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20%' }}>
        <p>{t('youtube_rejection_cookies_message')}</p>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {!cookiesAccepted && (
        <CookieBannerModal
          isOpen={!cookiesAccepted}
          onAccept={handleAcceptCookies}
          onReject={() => setCookiesRejected(true)}
        />
      )}
      {cookiesAccepted && videoId && (
        <iframe
          title={`YouTube video: ${videoId}`}
          width="300"
          height="200"
          src={`https://www.youtube.com/embed/${videoId}?autoplay=0&controls=1&modestbranding=1&rel=0`}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
        ></iframe>
      )}
    </div>
  );
};

export default YouTubePage;