// definitions.js
const definitions = {
    'Positivist': 'A paradigm that assumes an objective reality independent of the observer, and uses the scientific method to discover universal laws.',
    'Pragmatic': 'A paradigm that flexibly combines quantitative and qualitative techniques to solve practical problems.',
    'Interpretive': 'A paradigm that assumes reality is socially and culturally constructed, and that knowledge is context-relative.',
   
    'Post-Positivist': 'This perspective acknowledges the limitations of positivism, emphasizing that all observation is fallible and theory-laden.',
    'Constructivist': 'A perspective suggesting that knowledge is constructed rather than discovered, emphasizing the importance of social interactions and cultural norms.',
    'Post-Structuralist': 'A theoretical perspective that critiques and addresses limitations found within structuralism, emphasizing the instability of human meaning.',
    'Critical Theory': 'A theoretical framework that critiques society and culture by applying knowledge from the social sciences and humanities.',
    'Feminist Theory': 'A framework that aims to understand gender inequality and focuses on gender politics, power relations, and sexuality.',
    
    'Quantitative': 'An approach focused on quantifying data and generalizing results from sample to population through the use of statistical methods.',
    'Mixed': 'An approach that combines quantitative and qualitative research methods to encompass the strengths of both.',
    'Qualitative': 'An approach aimed at gaining a deep understanding of a specific organization or event, rather than surface description of a large sample of a population.',
    
    'Deductive': 'A form of reasoning that works from the more general to the more specific, often used in hypothesis testing.',
    'Inductive': 'A reasoning approach that involves collecting data to draw a general conclusion or to formulate theories.',
    
    'Explanatory - Cause-Effect': 'An objective focused on understanding the causal relationships between variables.',
    'Predictive': 'This objective aims to forecast the occurrence of phenomena based on changes in variables.',
    'Comparative': 'Aims to assess differences between two or more groups or conditions to determine what effects may arise from variations.',
    'Analytical': 'Focuses on understanding complex data and relationships through breaking down information into components.',
    'Developmental': 'An objective concerned with changes over time and processes that influence growth or decline.',
    'Instrument Development': 'The objective of creating, adapting, or validating a new measurement instrument in research.',
    'Descriptive': 'Aims to accurately describe characteristics of a particular individual, situation, or group.',
    'Evaluative': 'Concerned with assessing the value or impact of an intervention or condition.',
    'Exploratory': 'Aims to investigate little-understood phenomena, to identify or discover underlying patterns or relationships.',
    'Synthesis': 'Combines data from multiple sources to develop a comprehensive understanding of a phenomenon.',
    'Explanatory - Meaning': 'Seeks to understand the deeper significance or meanings of phenomena.',
    'Theory-Generation': 'Aims to build new theoretical frameworks that explain observed phenomena.',
    'Case-Specific': 'Focuses on detailed investigation of a single instance or case, often used in qualitative research.',
   
    'Experimental': 'A study involving an intervention or treatment manipulated by the researcher to observe effects on a dependent variable.',
    'Quasi-experimental': 'Similar to experimental designs but lacks random assignment to treatment or control.',
    'Observational': 'Studies where the researcher observes subjects without manipulation, often in natural settings.',
    
    'Factorial Experiments': 'Experimental designs that involve two or more factors (independent variables) and assess their interactive and independent effects on dependent variables.',
    'Independent Group Comparison': 'A research design where different groups of subjects are exposed to different treatments and their outcomes are compared.',
    'Dependent Group Comparison': 'Also known as a matched or paired design, where the same subjects are used in each condition of the experiment, or subjects are matched across conditions for comparison.',
    'Cohort': 'A longitudinal study design that follows a group of people who share a defining characteristic over time, often to study the development of diseases.',
    'Case-Control': 'A study design used to identify factors that may contribute to a medical condition by comparing subjects who have that condition (the "cases") with patients who do not have the condition but are otherwise similar (the "controls").',
    'Correlational Design': 'A non-experimental study that measures two or more variables to determine the relationships between them, without manipulating any variables.',
    'Repeated Measures Design': 'An experimental design in which the same subjects are tested under each condition, allowing comparison of their responses across different interventions or over time.',
    'Single-Case Experimental Design': 'A rigorous study design that involves repeated, systematic measurement of a baseline control followed by an intervention to measure the effect on a single subject.',
    'Survey Design': 'A research design that collects data from a sample of individuals through their responses to questions, often used to collect standardized information from a large number of people.',
    'Time-Series Analysis': 'A statistical technique that analyzes a sequence of data points, typically measured at successive points in time, to identify trends, cycles, and seasonal variations in the data.',
    'Cross-Over Trials': 'Experimental designs where participants receive a sequence of different treatments, allowing each subject to serve as their own control.',
    'Exploratory Sequential Mixed Methods': 'A design that starts with qualitative data collection and analysis, which is then used to inform subsequent quantitative research, enhancing and explaining the quantitative results with qualitative data.',
    'Explanatory Sequential Mixed Methods': 'A research design where quantitative data collection and analysis are followed by qualitative data collection and analysis to explain or contextualize the quantitative findings.',
    'Systematic Review': 'A research method that comprehensively collects and critically evaluates multiple research studies or papers to synthesize findings related to a specific research question.',
    'Meta-analysis': 'A statistical technique that integrates the results of several independent studies considered to be combinable.',
    'Integrative Review': 'A broad type of review that systematically searches, critiques, and synthesizes representative literature on a topic in an integrated way to generate new frameworks and perspectives on the topic.',
    'Scoping Review': 'A type of literature review that aims to map the key concepts underpinning a research area and the main sources and types of evidence available.',
    'Narrative Review': 'An informal review that synthesizes evidence on a topic but is not structured as systematically as a systematic review.',
    'Action Research': 'A participatory form of research that involves iterative problem-solving led by individuals working with others in teams or as part of a "community of practice" to improve the way they address issues and solve problems.',
    'Case Study': 'A research design that explores the complexities of a single case or instance in depth within its real-life context, often using a variety of data sources.',
    'Phenomenology': 'A qualitative research design focused on describing what all participants have in common as they experience a phenomenon.',
    'Grounded Theory': 'A research method that generates theories from data systematically gathered and analyzed through the research process.',
    'Ethnography': 'A qualitative research method used to study cultures through close observation, reading, and interpretation of the data gathered from fieldwork.',
    'Narrative Inquiry': 'A method of qualitative research that investigates the ways humans experience the world depicted through their stories.',

    'Prospective': 'A study design in which participants are followed over time, with data being collected at future dates.',
    'Longitudinal': 'A research design that involves repeated observations of the same variables (e.g., people) over short or long periods of time.',
    'Cross-sectional': 'A study design that analyzes data from a population, or a representative subset, at a specific point in time.',
    'Retrospective': 'A study design where researchers look back at historical data to examine exposures to suspected risk or protection factors in relation to an outcome that is established at the start of the study.',

    'Tests': 'Standardized measures designed to assess behavior, abilities, or other characteristics.',
    'Surveys': 'A method of gathering information from a sample of individuals by asking them questions and recording their responses.',
    'Questionnaires': 'A research instrument consisting of a series of questions for the purpose of gathering information from respondents.',
    'Task-Based Assessments': 'Evaluations where subjects perform specific tasks to measure their skills or knowledge in a particular area.',
    'Behavioral Checklists': 'A method of assessment that involves observing a person’s behavior and marking it against a list of expected behaviors.',
    'Observations': 'A method of collecting data by watching and recording behaviors or events as they occur in their natural settings.',
    'Archival Records': 'Use of existing documents and records as a form of data collection in research.',
    'Literature Screening': 'The process of reviewing published materials such as books, journals, and online sources to gather information relevant to a research topic.',
    'Ecological Momentary Assessment (EMA)': 'A form of self-report diary method that involves recording behaviors and experiences in real-time while subjects go about their daily lives.',
    'Document Analysis': 'A systematic procedure for reviewing or evaluating documents—both printed and electronic (computer-based and Internet-transmitted) material.',
    'Interviews': 'A method of data collection that involves direct questioning of respondents, typically in a structured or semi-structured format.',
    'Focus Groups': 'A form of qualitative research in which a group of people are asked about their perceptions, opinions, beliefs, and attitudes towards a product, service, concept, advertisement, idea, or packaging.',
    'Reflexive Journals': 'A method where participants record insights and reflect on their own experiences over the course of the research period.',
    'Virtual Ethnography': 'An approach to ethnographic research that involves observing and interacting with participants in online environments and digital spaces.',

    'Individuals': 'Data collection and analysis focus on individual participants, their behaviors, responses, and characteristics.',
    'Groups': 'Focuses on collecting data from sets of individuals interacting as a group, observing the dynamics and outcomes of those interactions.',
    'Institutions': 'Research focusing on institutional characteristics, such as policies, practices, and organizational structures, often in a comparative framework.',
    'Documents/Artifacts': 'Research that involves the analysis of physical and digital objects that provide information about human activity and historical contexts.',

    'Random': 'Sampling technique where each member of a population has an equal chance of being selected.',
    'Stratified Random Sampling': 'A method of sampling that involves dividing a population into smaller groups known as strata based on shared attributes or characteristics and randomly sampling from each stratum.',
    'Probability': 'Sampling method where each member of the population has a known and typically non-zero chance of selection.',
    'Systematic': 'A sampling method where samples are selected according to a fixed periodic interval from a randomly selected starting point.',
    'Quota Sampling': 'A non-probability sampling technique wherein the researcher ensures equal or proportionate representation of certain characteristics in the sample based on known proportions in the target population.',
    'Comprehensive': 'Sampling that attempts to include every subject in the population in the study.',
    'Criterion-based': 'Sampling technique where subjects are chosen based on specific criteria and characteristics.',
    'Non-probability': 'Sampling methods where not all members of the population have a chance of participating in the study.',
    'Purposive': 'Sampling where participants are selected based on the specific purpose of the study and the researcher’s knowledge and judgment.',
    'Snowball': 'Sampling technique where existing study subjects recruit future subjects from among their acquaintances.',
    'Theoretical Sampling': 'Sampling method used in qualitative research where data collection is driven by concepts derived from the researcher’s developing theory.',
    'Saturation-based': 'Sampling method in qualitative research where sampling continues until no new information is obtained and redundancy is achieved.',
    'Convenience': 'Sampling method where subjects are selected based on their easy availability and proximity to the researcher.',

    'Randomization': 'The process of making groups or samples random to ensure that each participant has an equal opportunity of being chosen.',
    'Double-Blinding': 'A method where neither the participants nor the researchers know who is receiving the experimental treatment, to prevent bias in treatment administration and assessment of outcomes.',
    'Placebo Control': 'A control group in a study that receives a placebo treatment, which has no therapeutic effect, used to compare against the experimental group to determine the effect of the treatment.',
    'Blinding': 'A technique used in research to eliminate bias by hiding the details of the study from participants, researchers, or both.',
    'Control Groups': 'Groups in experimental research that do not receive the experimental treatment and are used as a benchmark to measure the effects of the treatment.',
    'Counterbalancing': 'A method used to control for any effect that the order of presenting stimuli might have on the dependent variable.',
    'Matching': 'A technique whereby participants are paired based on certain criteria before being assigned to different groups.',
    'Repeated Measures': 'The statistical approach used to analyze data collected from the same subjects at multiple time points.',
    'Multiple Baseline Control': 'A design that involves observing behavior over time before and after the intervention is introduced, but staggered across different contexts or individuals.',
    'Treatment Withdrawal': 'A method where an effective treatment is removed to verify that behaviors or conditions return to baseline, confirming the treatment’s efficacy.',
    'Stratification': 'The process of dividing members of the population into homogeneous subgroups before sampling.',
    'Use of Benchmarks': 'The practice of comparing performance against a set of standards or an industry average.',
    'Historical Control': 'Use of previously collected data from an external or historical group as a control group in a study’s analysis.',

    'Pilot Testing': 'Initial small-scale study conducted to test the feasibility, time, cost, risk, and adverse events involved in a research study.',
    'Test-retest': 'A method for determining the reliability of a test by measuring a single individual or group of individuals at two different times.',
    'Inter-rater Reliability': 'The degree of agreement among independent observers who report observations of the same events.',
    'Simulation': 'The act of simulating something first to predict its behaviors in real-world scenarios.',
    'Sequential Exploratory Strategy': 'A strategy in mixed methods research involving an initial phase of qualitative data collection and analysis, followed by a phase of quantitative data collection and analysis.',
    'Triangulation': 'The use of multiple methods or data sources in qualitative research to develop a comprehensive understanding of phenomena.',
    'Adaptive Sampling': 'Sampling methods that are adjusted dynamically based on the data collected throughout the study period.',
    'Iterative Refinement': 'A process where a procedure is repeatedly revisited and refined based on the outcomes of successive trials or tests.',
    'Constant Comparison': 'A qualitative research method where findings are continuously compared with data collected during the study to identify patterns and themes.',
    'Thematic Saturation': 'The point at which no additional data are being found whereby researchers can develop properties of the category further.',
    'Participatory Feedback Loops': 'A process where feedback from stakeholders or participants is used to refine and improve the study or intervention.',
    'Validity Testing': 'A process for evaluating how well a test measures what it is intended to measure.',
    'Reliability Testing': 'A method to determine the consistency of a test or measurement tool over time and across different observers or contexts.',
    'Data Cleaning': 'The process of detecting and correcting (or removing) errors and inconsistencies in data to improve its quality and reliability.',

    'Numeric Data': 'Quantifiable information that can be measured and expressed as numbers, used for statistical analysis.',
    'Physiological Measures': 'Data collected from biological functions, such as heart rate or brain activity, often used in medical and psychological research.',
    'Geospatial Data': 'Information that includes geographic locations and is often represented in maps or used for spatial analysis.',
    'Social Media Data': 'Content and interactions from social media platforms, useful for analyzing trends, sentiments, and public opinion.',
    'Images': 'Visual data from photographs, scans, or other imaging technologies, used in fields from medical imaging to machine learning.',
    'Audio/Video Data': 'Recordings of sound and visual images, used for everything from entertainment media studies to surveillance and academic research.',
    'Textual Data': 'Data in the form of written content, which can be qualitative and analysed for patterns in language, sentiment, or structure.',

    'Continuous (Ratio)': 'Data with a meaningful zero point, allowing for the measurement of ratios between data points. Examples include height, weight, and age.',
    'Continuous (Interval)': 'Data where intervals between values are meaningful, such as temperatures in Celsius or Fahrenheit, without a true zero point.',
    'Ordinal': 'Categorical data where the order of values is significant but the differences between values are not uniformly spaced, such as a rating scale.',
    'Nominal': 'Categorical data where the order of values is not significant, commonly used to label variables without quantitative value, such as gender or race.',
    
    'Inferential Statistics': 'Techniques that allow conclusions to extend beyond immediate data alone, aiming to draw inferences about a population based on a sample.',
    'Chi-square and Contingency Table Analysis': 'Statistical tests used to determine if relationships exist between categorical variables in a contingency table.',
    'Logistic Regression': 'A statistical model used to predict the probability of a binary outcome based on one or more predictor variables.',
    'Correspondence Analysis': 'A method used to visualize the relationships between two categorical variables and their levels.',
    'Multivariate Analysis': 'Statistical procedures for analysis involving multiple variables to understand relationships among them and their influence on an outcome variable.',
    'Descriptive Statistics': 'Statistics that quantitatively describe or summarize features of a dataset.',
    'Survival Analysis': 'A branch of statistics that deals with analyzing the expected duration of time until one or more events happen, such as death in biological organisms and failure in mechanical systems.',
    'Sequential Analysis': 'A statistical method where the sample size is not fixed in advance; instead data are evaluated as they are collected, and further sampling is stopped as soon as significant results are observed.',
    'Structural Equation Modeling (SEM)': 'A multivariate statistical analysis technique used to analyze structural relationships between measured variables and latent constructs.',
    'Latent Class Analysis': 'A subset of structural equation modeling used to group cases, such as individuals or items, into classes by patterns of observed categorical variables.',
    'Bayesian Analysis': 'A method of statistical inference in which Bayes\' theorem is used to update the probability for a hypothesis as more evidence or information becomes available.',
    'Cluster Analysis': 'A statistical method used to group a set of objects in such a way that objects in the same group are more similar to each other than to those in other groups.',
    'Mosaic Plots': 'Graphical representations used to visualize data from two or more categorical variables. It is a multi-dimensional extension of a stacked bar chart.',
    'Diversity Indices': 'Metrics used to measure the diversity of categorical data by quantifying the variability of its categories.',
    'Visual Analytics': 'The science of analytical reasoning supported by interactive visual interfaces.',
    'Mixed Methods Integration': 'A research approach that combines quantitative and qualitative data collection and analysis methods in a single study or coordinated studies.',
    'Sentiment Analysis': 'The use of natural language processing, text analysis, and computational linguistics to identify, extract, and quantify affective states and subjective information.',
    'Content Analysis': 'A research technique used to objectively and systematically identify specified characteristics of messages.',
    'Thematic Analysis': 'A method for identifying, analyzing, and reporting patterns (themes) within data.',
    'Grounded Theory Coding': 'A systematic methodology in the social sciences involving the construction of theories through the methodical gathering and analysis of data.',
    'Open Coding': 'The part of the content analysis process where raw data are initially broken down into discrete parts, closely examined, and compared for similarities and differences.',
    'Axial Coding': 'A type of coding in grounded theory methodology that involves categorizing data after initial codes are identified.',
    'Selective Coding': 'The process of selecting the core category, systematically relating it to other categories, and filling in categories that need further refinement and development.',
    'Phenomenological Analysis': 'A qualitative research approach that seeks to understand and describe how people experience a particular phenomenon.',
    'Narrative Analysis': 'A method used to analyze content from various sources, such as interviews or books, where the data are collected as stories.',
    'Case-Specific Analysis': 'Analysis aimed at understanding particular distinctive aspects of a single case or small number of cases.',
    'Cross-Case Analysis': 'A comparative analysis process where multiple cases are compared and contrasted to identify patterns and variations across them.',
    
    'Ethical Committee Approval': 'An approval granted by a formal body intended to protect the rights and welfare of research participants.',
    'Informed Consent': 'A process in which a participant is educated about key elements of a study before agreeing to participate.',
    'Anonymization': 'The process of removing personally identifiable information from data sets, so that the people whom the data describe remain anonymous.',
    'Confidentiality': 'The obligation to protect and not disclose personal or sensitive information provided by participants during a study.',
    'Data Protection': 'Measures and policies implemented to ensure the privacy and security of data, in compliance with laws and regulations, such as GDPR.'
};

export default definitions;
