// AudioManager.jsx

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import feedbackDataOriginalPVQD from "./../feedback-data/feedbackDataOriginalPVQD";
import AudioUploader from "../shared-files/tools-and-functions/AudioUploader";

const AudioManager = ({ mode, onSampleSelected }) => {
  const { t } = useTranslation();
  const [randomSample, setRandomSample] = useState(null);

  useEffect(() => {
    if (mode === "random" && !randomSample) {
      // Només selecciona una vegada si no hi ha cap mostra aleatòria seleccionada
      const randomIndex = Math.floor(Math.random() * feedbackDataOriginalPVQD.length);
      const sample = feedbackDataOriginalPVQD[randomIndex];
      setRandomSample(sample); // Guarda la mostra seleccionada
      onSampleSelected({
        sampleLink: sample.sampleLink,
        sampleName: sample.name || `Sample ${randomIndex + 1}`,
      });
    }
  }, [mode, randomSample, onSampleSelected]);

  const handleFileUpload = (uploadedFile) => {
    const sampleNameFromUpload = uploadedFile.name.replace(/\.[^/.]+$/, "");
    const sampleLink = URL.createObjectURL(uploadedFile);
    onSampleSelected({ sampleLink, sampleName: sampleNameFromUpload });
  };

  return (
    <div>
      {mode === "manual" && (
        <select
          onChange={(e) => {
            const sample = feedbackDataOriginalPVQD[parseInt(e.target.value, 10)];
            onSampleSelected({
              sampleLink: sample.sampleLink,
              sampleName: sample.name || `Sample ${parseInt(e.target.value, 10) + 1}`,
            });
          }}
        >
          <option value="">{t("select_a_sample")}</option>
          {feedbackDataOriginalPVQD.map((sample, index) => (
            <option key={index} value={index}>
              {sample.name || `Sample ${index + 1}`}
            </option>
          ))}
        </select>
      )}

      {mode === "upload" && <AudioUploader onFileUpload={handleFileUpload} />}
    </div>
  );
};

export default AudioManager;