// Funció per convertir la llista plana en arbre jeràrquic
export function buildTreeFromItems(items) {
    const stack = [];
    const root = [];
  
    items.forEach((item) => {
      const node = { id: item.id, label: item.label, children: [] };
      if (item.level === 0) {
        root.push(node);
        stack[0] = node;
      } else {
        const parent = stack[item.level - 1];
        if (!parent) {
          root.push(node);
          stack[0] = node;
        } else {
          parent.children.push(node);
        }
        stack[item.level] = node;
      }
      stack.length = item.level + 1;
    });
  
    return root;
  }  