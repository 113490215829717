// VocalAttributeRatingInput.jsx

import React from 'react';

function VocalAttributeRatingInput({
  label,
  value = 0, // Ara només un valor numèric
  setValue,
  index,
}) {
  const sliderId = `slider-${label}-${index}`;

  const onSliderChange = (event) => {
    setValue(parseInt(event.target.value, 10));
  };

  return (
    <div className="capevr-vocal-attribute-rating">
      <div className="capevr-rating-row">
        <label htmlFor={sliderId} className="capevr-rating-label">
          {label}
        </label>
        <input
          type="range"
          id={sliderId}
          name={sliderId}
          min="0"
          max="100"
          value={value}
          onChange={onSliderChange}
          className="capevr-slider"
        />
      </div>
    </div>
  );
}

export default VocalAttributeRatingInput;