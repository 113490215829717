// ConsentModal.jsx

// TODO adaptar el consentiment pel projecte EAR-V

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function ConsentModal({ 
  isOpen, 
  onRequestClose, 
  onConsentChange, 
  onSubmit, 
  fontSize = '1em' 
}) {
  const [accepted, setAccepted] = useState(null); // Inicialitzar a null perquè cap opció estigui seleccionada

  useEffect(() => {
    setAccepted(null); // Reinicialitzar a null cada cop que el modal s'obre
  }, [isOpen]);

  const handleConsentChange = (isAccepted) => {
    setAccepted(isAccepted);
    onConsentChange(isAccepted);
  };

  const handleSubmit = () => {
    if (accepted === null) {
      alert('Please select an option before submitting.');
    } else {
      onSubmit();
      onRequestClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Consent Modal"
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '500px',
          minWidth: '300px',
          padding: '20px',
          maxHeight: '80%',
          overflowY: 'auto',
          zIndex: 1000,
        },
        overlay: {
          zIndex: 999,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <h2 style={{ fontSize: '1.2em', marginBottom: '20px' }}>Consent to Share Data</h2>
      <div style={{ fontSize, marginBottom: '20px' }}>
        <p>
          By submitting your categorization, you agree to share your data anonymously for research purposes. 
          For more details, please read our <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
        </p>
      </div>
      <div style={{ fontSize }}>
        <label style={{ display: 'block', marginBottom: '10px' }}>
          <input
            type="radio"
            name="consent"
            checked={accepted === true}
            onChange={() => handleConsentChange(true)}
          />
          I agree to share my data anonymously.
        </label>
        <label style={{ display: 'block', marginBottom: '20px' }}>
          <input
            type="radio"
            name="consent"
            checked={accepted === false}
            onChange={() => handleConsentChange(false)}
          />
          I do not agree to share my data.
        </label>
      </div>
      <div style={{ textAlign: 'center' }}>
        <button 
          className="button-center" 
          onClick={handleSubmit} 
          style={{ marginRight: '10px' }}
        >
          Submit
        </button>
        <button 
          className="button-important" 
          onClick={onRequestClose}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
}

export default ConsentModal;