// ResourcesToolbox.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import './../../locales/i18n';
import { Link } from 'react-router-dom';
// import NavigationIcons from './../app-views/NavigationIcons';
//import React, { useState } from 'react';
//import { Link, useNavigate } from 'react-router-dom';
//import { useAuth } from './../../auth-and-backend/auth/authContext';
//import LoginModal from './../../auth-and-backend/modals/LoginModal';
//import SignUpModal from './../../auth-and-backend/modals/SignUpModal';

function ResourcesToolbox() {
    const { t } = useTranslation();
    //const { user } = useAuth();
    //const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    //const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
    //const navigate = useNavigate();

    // const handleRestrictedLinkClick = (e) => {
    //     if (!user) {
    //         e.preventDefault();
    //         setIsLoginModalOpen(true);
    //     }
    // };

    // const handleRestrictedButtonClick = (profile) => {
    //     if (!user) {
    //         setIsLoginModalOpen(true);
    //     } else {
    //         handleProfileSelect(profile);
    //     }
    // };

    // const renderLockIcon = (isRestricted) => {
    //     return isRestricted ? (
    //         user ? (
    //             <i className="fas fa-lock-open" style={{ color: '#a5d3ce' }}></i>
    //         ) : (
    //             <i className="fas fa-lock" style={{ color: 'red' }}></i>
    //         )
    //     ) : null;
    // };

    // const handleProfileSelect = (profile) => {
    //     const path = profile === 'instructor' ? '/cape-v-workshop-instructor' : '/cape-v-workshop-student';
    //     navigate(path, { state: { profile } });
    // };

    return (
        <div className="icon-button-container">
                <div className="icon-container">
                    <Link to="/join-a-session" className="icon-button">
                        <img src="/images/join-session-icon.png" alt="Join a Session Icon" className="link-icon" />
                        <span className="link-text">{t('join_a_session')}</span>
                    </Link>
                </div>

                <div className="icon-container">
                    <Link to="/preset-resources" className="icon-button">
                        <img src="/images/preset-resources-icon.png" alt="Preset Resources Icon" className="link-icon" />
                        <span className="link-text">{t('preset_resources')}</span>
                    </Link>
                </div>

                <div className="icon-container">
                    <Link to="/resource-builder" className="icon-button">
                        <img src="/images/resources-builder-icon.png" alt="Resource Buildern Icon" className="link-icon" />
                        <span className="link-text">{t('resource_builder')}</span>
                    </Link>
                </div>
            </div>
    );
}

export default ResourcesToolbox;