// RatingConditions.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';

function RatingConditions({
  voiceSource,
  setVoiceSource,
  listeningDevice,
  setListeningDevice,
  auditoryAnchors,
  setAuditoryAnchors,
  rater,
  setRater,
  ratingDate,
  setRatingDate,
  timesPlayed,
  setTimesPlayed,
}) {
  const { t } = useTranslation();

  return (
    <div className="capevr-conditions-section">
      {/* Voice Type */}
      <select
        id="voiceSource"
        value={voiceSource}
        onChange={(e) => setVoiceSource(e.target.value)}
        className="input-field"
      >
        <option value="" disabled>
          {t('live_voice_recorded_voice')}
        </option>
        <option value="liveVoice">{t('live_voice')}</option>
        <option value="recordedVoice">{t('recorded_voice')}</option>
      </select>

      {/* Listening Device */}
      <select
        id="listeningDevice"
        value={listeningDevice}
        onChange={(e) => setListeningDevice(e.target.value)}
        className="input-field"
      >
        <option value="" disabled>
          {t('headphones_speakers')}
        </option>
        <option value="headphones">{t('headphones')}</option>
        <option value="speakers">{t('speakers')}</option>
      </select>

      {/* Auditory Anchors */}
      <select
        id="auditoryAnchors"
        value={auditoryAnchors}
        onChange={(e) => setAuditoryAnchors(e.target.value)}
        className="input-field"
      >
        <option value="" disabled>
          {t('auditory_anchors_yes_no')}
        </option>
        <option value="yes">{t('auditory_anchors_yes')}</option>
        <option value="no">{t('auditory_anchors_no')}</option>
      </select>

      {/* Rater */}
      <input
        type="text"
        id="rater"
        value={rater}
        onChange={(e) => setRater(e.target.value)}
        placeholder={t('rater_id')}
        className="input-field"
      />

      {/* Date */}
      <input
        type="date"
        id="ratingDate"
        value={ratingDate}
        onChange={(e) => setRatingDate(e.target.value)}
        className="input-field"
      />

      {/* Number of Times Sample Was Played */}
      <input
        type="number"
        id="timesPlayed"
        value={timesPlayed} // Nom de la variable actualitzat
        onChange={(e) => setTimesPlayed(e.target.value)} // Funció actualitzada
        className="input-field"
        placeholder={t('number_of_times_sample_was_played')} // La clau de traducció es manté igual
      />
    </div>
  );
}

export default RatingConditions;