// OverallImpression.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';

function OverallImpression({ overallImpressionComment, setOverallImpressionComment }) {
  const { t } = useTranslation();

  return (
    <div className="capevr-radio-group">
      {/* Etiqueta a la columna de l'esquerra */}
      <label className="capevr-radio-label">{t('overall_impression')}</label>

      {/* Caixa de text a la columna de la dreta */}
      <div className="capevr-textarea-wrapper">
        <textarea
          id="overallImpressionComment"
          value={overallImpressionComment}
          onChange={(e) => setOverallImpressionComment(e.target.value)}
          placeholder={t('describe')}
          className="capevr-textarea"
        />
      </div>
    </div>
  );
}

export default OverallImpression;