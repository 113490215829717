// TallerCAPEVWorkshopInstructor.jsx

import React, { useState, useEffect } from 'react';
import TallerInstructorSessionCreator from './TallerInstructorSessionCreator';
import TallerInstructorView from './TallerInstructorView';
import { useAuth } from '../../../auth-and-backend/auth/authContext';
import NavigationIcons from '../../../views/app-views/NavigationIcons';

const TallerCAPEVWorkshopInstructor = () => {
  const { user } = useAuth();
  const [sessionCode, setSessionCode] = useState('');
  const [isSessionActive, setIsSessionActive] = useState(false);
  const [selectedSample, setSelectedSample] = useState(null);
  const [consensusData, setConsensusData] = useState(null);
  const [consensusSubmitted, setConsensusSubmitted] = useState(false);

  const handleSessionCreated = (code, sample) => {
    setSessionCode(code);
    setSelectedSample(sample);
    setIsSessionActive(true);
  };

  const handleConsensusSubmit = (data) => {
    setConsensusData(data);
    setConsensusSubmitted(true);
    setIsSessionActive(true); 
  };

  const handleStartAnotherSession = () => {
    setIsSessionActive(false);
    setConsensusSubmitted(false);
    setConsensusData(null);
    setSessionCode('');
    setSelectedSample(null);
  };

  useEffect(() => {
    if (isSessionActive) {
      window.scrollTo({
        top: 0,
        behavior: 'auto',
      });
    }
  }, [isSessionActive]);

  return (
    <div>
      {!isSessionActive && (
        <TallerInstructorSessionCreator onSessionCreated={handleSessionCreated} />
      )}

      {isSessionActive && (
        <TallerInstructorView 
          sessionCode={sessionCode} 
          userId={user?._id} 
          onConsensusSubmit={handleConsensusSubmit}
          consensusData={consensusData}
          consensusSubmitted={consensusSubmitted}
          onStartAnotherSession={handleStartAnotherSession}
          selectedSample={selectedSample}
        />
      )}
        <div className="navigation-container">
          <NavigationIcons />
        </div>
    </div>
  );
};

export default TallerCAPEVWorkshopInstructor;