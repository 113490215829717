// ResearchMethodologyGrid.jsx

// TODO Introduir selecció de nivell (beginner, intermediate, advanced, expert) per personalitzar l'experiència.
// TODO Canviar regles de compatibilitat per graus de familiaritat: 
// Implement a familiarity-based system for guiding users in methodology selection
// Instead of strict compatibility/incompatibility rules, create a system that highlights the familiarity level of each option:
// 1. Define familiarity levels ('High', 'Medium', 'Low') for each option in the methodologyOptions object.
// 2. Highlight 'High' familiarity options with vibrant colors/icons, 'Medium' familiarity with neutral tones, and 'Low' familiarity with subdued tones.
// 3. Ensure all options remain selectable, but provide subtle guidance through visual cues and tooltips explaining the level of familiarity.
// 4. Add a progress bar or label to indicate the overall familiarity score of the selected combination, without enforcing restrictions.
// 5. Design this system to encourage exploration and creativity while providing clear guidance on common methodological practices.


import React, { useState, useCallback, useEffect } from 'react';
import compatibilityRules from './compatibilityRules'; 
import methodologyOptions from './methodologyOptions';
import CategoryTable from './CategoryTable';
import SelectedOptionsSummary from './SelectedOptionsSummary';
import CategoryVisibilityControls from './CategoryVisibilityControls';

function ResearchMethodologyGrid() {
    const [visibleCategories, setVisibleCategories] = useState(Object.keys(methodologyOptions));
    const [selectedOptions, setSelectedOptions] = useState({});
    const [disabledOptions, setDisabledOptions] = useState({});
    const [useCompatibilityRules, setUseCompatibilityRules] = useState(true);

    const fixedOrder = Object.keys(methodologyOptions);
    const singleSelectionCategories = ['Paradigm', 'Approach'];

    // Update disabled options based on the current state of useCompatibilityRules
    const updateDisabledOptions = useCallback(() => {
        const newDisabledOptions = {};

        if (useCompatibilityRules) {
            Object.entries(selectedOptions).forEach(([category, option]) => {
                if (compatibilityRules[category]?.[option]) {
                    Object.keys(methodologyOptions).forEach((otherCategory) => {
                        if (otherCategory !== category) {
                            newDisabledOptions[otherCategory] = [
                                ...(newDisabledOptions[otherCategory] || []),
                                ...methodologyOptions[otherCategory].filter(
                                    (opt) => !compatibilityRules[category][option][otherCategory]?.includes(opt)
                                )
                            ];
                        }
                    });
                }
            });
        } else {
            // Clear disabled options if compatibility rules are turned off
            Object.keys(methodologyOptions).forEach(category => {
                newDisabledOptions[category] = []; // Ensure all categories are enabled
            });
        }

        setDisabledOptions(newDisabledOptions);
    }, [selectedOptions, useCompatibilityRules]); // Ensure to include useCompatibilityRules as a dependency

    // Recalculate disabled options when selections change or the use of compatibility rules is toggled
    useEffect(() => {
        updateDisabledOptions();
    }, [selectedOptions, useCompatibilityRules, updateDisabledOptions]);

    // Handle option selection/deselection
    const toggleOption = (category, option) => {
        setSelectedOptions(prev => {
            const newSelections = { ...prev };

            if (!newSelections[category]) {
                newSelections[category] = [];
            }

            if (singleSelectionCategories.includes(category)) {
                if (newSelections[category] === option) {
                    delete newSelections[category];
                } else {
                    newSelections[category] = option;
                }
            } else {
                if (newSelections[category].includes(option)) {
                    newSelections[category] = newSelections[category].filter(opt => opt !== option);
                    if (newSelections[category].length === 0) {
                        delete newSelections[category];
                    }
                } else {
                    newSelections[category].push(option);
                }
            }

            return newSelections;
        });
    };

    const toggleCategoryVisibility = (category) => {
        setVisibleCategories((prev) =>
            prev.includes(category) ? prev.filter((cat) => cat !== category) : [...prev, category]
        );
    };

    const selectAllCategories = () => {
        setVisibleCategories(fixedOrder);
    };

    const deselectAllCategories = () => {
        setVisibleCategories([]);
    };

    const handleReset = () => {
        setSelectedOptions({});
        setDisabledOptions({});
    };

    const sortedVisibleCategories = visibleCategories.sort(
        (a, b) => fixedOrder.indexOf(a) - fixedOrder.indexOf(b)
    );

    return (
        <div>
            <h1>Methodology Lab (beta version*)</h1>
            <p>Welcome to Methodology Lab (beta version), an interactive tool designed to streamline the research methodology selection process. This application allows users to explore various research components including paradigms, designs, and strategies. Here, you can see how different elements of research methodology typically interact and are often combined. The tool dynamically updates available choices and disables options less commonly associated with selected methods to aid users in making informed decisions.</p>
            <p>It's important to note that the suggestions provided by this tool are based on established practices and common methodological frameworks. They should be seen as guidance to help researchers make informed choices, rather than as strict rules. This approach ensures flexibility, allowing researchers to adapt their methodology according to the unique demands of their specific research context. Below, you will find steps to select categories and customize your methodology, and at the bottom of the page, definitions for each selected option are displayed for further clarity and insight.</p>
            <h4>STEP 1</h4>
            <p>Select the categories you want to display in the table:</p>

            {/* Buttons to select/deselect all checkboxes */}
            <div className="category-buttons" style={{ marginBottom: '20px' }}>
                <button onClick={selectAllCategories} className="navigation-button">
                    Select All
                </button>
                <button onClick={deselectAllCategories} className="navigation-button">
                    Deselect All
                </button>
            </div>

            {/* Category visibility controls */}
            <CategoryVisibilityControls
                fixedOrder={fixedOrder}
                visibleCategories={visibleCategories}
                toggleCategoryVisibility={toggleCategoryVisibility}
            />
                 <h4>STEP 2</h4>
                 <p>Choose options from the displayed categories to customize your research methodology. Use the toggle below to enable or disable guided mode, which offers suggestions based on typical methodological combinations.</p>
                <label style={{ display: 'block', marginBottom: '10px' }}>
                    <input
                        type="checkbox"
                        checked={useCompatibilityRules}
                        onChange={() => setUseCompatibilityRules(!useCompatibilityRules)}
                    />
                    Enable Guidance Mode
                </label>
                <CategoryTable
                sortedVisibleCategories={sortedVisibleCategories}
                methodologyOptions={methodologyOptions}
                selectedOptions={selectedOptions}
                disabledOptions={disabledOptions}
                toggleOption={toggleOption}
            />

            {/* Reset button */}
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <button onClick={handleReset} className="navigation-button">
                    Reset
                </button>
            </div>
            <h4>STEP 3</h4>
                 <p>Review and refine your selected methodology. After selecting your options, scroll down to review the detailed descriptions provided for each choice. This section helps you to understand the implications of your selections and ensure they align with the goals and requirements of your research. If needed, you can return to the table to modify your choices or explore alternative combinations. Once you are satisfied with your methodology, use the 'Copy Summary to Clipboard' button at the bottom to save your customized research design. This step is crucial for refining your methodology to suit the specific demands of your study.</p>
            <SelectedOptionsSummary selectedOptions={selectedOptions} />
            <p style={{ marginTop: '20px', fontStyle: 'italic' }}>
                * Please note that this is a beta version of the Methodology Lab. You may encounter errors or functionality issues. We welcome your feedback to help us improve. Thank you for your contributions!
            </p>
        </div>
    );
}

export default ResearchMethodologyGrid;