// TreeView.jsx

import React from 'react';

function TreeView({ tree }) {
  const renderTree = (nodes) => {
    return (
      <ul style={{ listStyleType: 'none', paddingLeft: '20px', position: 'relative' }}>
        {nodes.map((node, index) => (
          <li key={node.id} style={{ position: 'relative', paddingLeft: '20px' }}>
            {/* Línia vertical per connectar amb els fills */}
            {index !== nodes.length - 1 && (
              <span
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '10px',
                  height: '100%',
                  borderLeft: '1px solid #ccc',
                }}
              ></span>
            )}
            {/* Línia horitzontal cap al node */}
            <span
              style={{
                position: 'absolute',
                top: '10px',
                left: '-10px',
                width: '10px',
                borderTop: '1px solid #ccc',
              }}
            ></span>

            {/* Contingut del node */}
            <div
              style={{
                display: 'inline-block',
                padding: '4px 8px',
                backgroundColor: '#f9f9f9',
                border: '1px solid #ddd',
                borderRadius: '4px',
              }}
            >
              {node.label}
            </div>

            {/* Renderitzar els fills */}
            {node.children && renderTree(node.children)}
          </li>
        ))}
      </ul>
    );
  };

  return <div>{renderTree(tree)}</div>;
}

export default TreeView;