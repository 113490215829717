// AvailableConcepts.jsx

import React, { useState } from 'react';
import { Droppable, Draggable } from '@hello-pangea/dnd';

function AvailableConcepts({ availableConcepts, setAvailableConcepts }) {
  const [newConcept, setNewConcept] = useState('');

  const handleAddConcept = () => {
    if (!newConcept.trim()) return;
    const newConceptObj = {
      id: Date.now().toString(),
      label: newConcept.trim(),
    };
    setAvailableConcepts((prev) => [...prev, newConceptObj]);
    setNewConcept('');
  };

  return (
    <div
      style={{
        border: '1px solid #ccc',
        padding: '10px',
        borderRadius: '4px',
        minHeight: '200px',
        flex: 1,
      }}
    >
      <h3>Available Concepts</h3>
      <Droppable droppableId="availableConcepts">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              minHeight: '150px',
              padding: '10px',
              backgroundColor: '#f9f9f9',
              borderRadius: '4px',
            }}
          >
            {availableConcepts.map((concept, index) => (
              <Draggable key={concept.id} draggableId={concept.id} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      padding: '5px',
                      margin: '5px 0',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      backgroundColor: '#fff',
                      ...provided.draggableProps.style,
                    }}
                  >
                    {concept.label}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <div style={{ marginTop: '10px' }}>
        <input
          type="text"
          value={newConcept}
          onChange={(e) => setNewConcept(e.target.value)}
          placeholder="Add new concept..."
          className="input-field"
        />
        <button
          onClick={handleAddConcept}
          className="button-center"
        >
          Add Concept
        </button>
      </div>
    </div>
  );
}

export default AvailableConcepts;