// AttributesCustomization.jsx

import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { useTranslation } from "react-i18next";
import availableAttributesData from "./availableAttributes";

const AttributesCustomization = ({ selectedAttributes, setSelectedAttributes, onProceed, onBack }) => {
  const { t } = useTranslation();
  const [availableAttrs, setAvailableAttrs] = useState(availableAttributesData);
  const [newTag, setNewTag] = useState(""); // Estat per a etiquetes personalitzades
  const [error, setError] = useState(false);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return; // Si no hi ha destinació, sortim
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return; // Si no hi ha canvi de posició, no fem res
    }

    const reorderList = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === "availableAttributes") {
        setAvailableAttrs((prev) => reorderList(prev, source.index, destination.index));
      } else if (source.droppableId === "selectedAttributes") {
        setSelectedAttributes((prev) => reorderList(prev, source.index, destination.index));
        setError(false); // Elimina l'error si hi ha atributs seleccionats
      }
      return;
    }

    const moveItem = (sourceList, setSourceList, destinationList, setDestinationList, sourceIndex, destinationIndex) => {
      const movedItem = sourceList[sourceIndex];
      setSourceList((prev) => prev.filter((_, idx) => idx !== sourceIndex));
      setDestinationList((prev) => [
        ...prev.slice(0, destinationIndex),
        movedItem,
        ...prev.slice(destinationIndex),
      ]);
    };

    if (source.droppableId === "availableAttributes" && destination.droppableId === "selectedAttributes") {
      moveItem(availableAttrs, setAvailableAttrs, selectedAttributes, setSelectedAttributes, source.index, destination.index);
      setError(false); // Elimina l'error
    } else if (source.droppableId === "selectedAttributes" && destination.droppableId === "availableAttributes") {
      moveItem(selectedAttributes, setSelectedAttributes, availableAttrs, setAvailableAttrs, source.index, destination.index);
    }
  };

  const handleAddCustomTag = () => {
    if (!newTag.trim()) return; // No afegir etiquetes buides
    const newTagObj = { id: `custom-${Date.now()}`, translationKey: newTag.trim(), isCustom: true };
    setAvailableAttrs((prev) => [...prev, newTagObj]); // Afegir al contenidor de disponibles
    setNewTag(""); // Resetejar camp d'entrada
  };

  const handleNext = () => {
    if (selectedAttributes.length === 0) {
      setError(true);
    } else {
      setError(false);
      onProceed();
    }
  };

  return (
    <div style={{ padding: "20px", maxWidth: "700px", margin: "0 auto" }}>
      <h2 style={{ textAlign: "center" }}>{t("customize_attributes")}</h2>
      <DragDropContext onDragEnd={onDragEnd}>
        <div style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>
          {/* Available Attributes */}
          <Droppable droppableId="availableAttributes">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  flex: 1,
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "10px",
                  minHeight: "300px",
                  backgroundColor: "#f9f9f9",
                }}
              >
                <h3>{t("available_attributes")}</h3>
                {availableAttrs.map((attr, index) => (
                  <Draggable key={attr.id} draggableId={attr.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          padding: "8px",
                          marginBottom: "8px",
                          backgroundColor: attr.isCustom ? "#d2e9e6" : "#fff", // Diferenciar etiquetes personalitzades
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          ...provided.draggableProps.style,
                        }}
                      >
                        {t(attr.translationKey)}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}

                {/* Input per afegir etiquetes personalitzades */}
                <div style={{ marginTop: "20px" }}>
                  <input
                    type="text"
                    value={newTag}
                    onChange={(e) => {
                      setNewTag(e.target.value);
                      setError(false); // Elimina l'error si s'està escrivint una etiqueta
                    }}
                    placeholder={t("add_custom_attribute")}
                    className="input-field"
                  />
                  <button
                    onClick={handleAddCustomTag}
                    className="button-center"
                  >
                    {t("add")}
                  </button>
                </div>
              </div>
            )}
          </Droppable>

          {/* Selected Attributes */}
          <Droppable droppableId="selectedAttributes">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  flex: 1,
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "10px",
                  minHeight: "300px",
                  backgroundColor: "#f9f9f9",
                }}
              >
                <h3>{t("selected_attributes")}</h3>
                {selectedAttributes.map((attr, index) => (
                  <Draggable key={attr.id} draggableId={attr.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          padding: "8px",
                          marginBottom: "8px",
                          backgroundColor: "#fff",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          ...provided.draggableProps.style,
                        }}
                      >
                        {t(attr.translationKey)}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>

      {error && (
        <p style={{ color: "red", textAlign: "center", marginTop: "10px" }}>
          {t("error_select_at_least_one_attribute")}
        </p>
      )}

      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
        <button
          className="button-center"
          onClick={onBack}
        >
          {t("back_to_sample_selection")}
        </button>

        <button
          className="button-center"
          onClick={handleNext}
        >
          {t("next_to_scale_customization")}
        </button>
      </div>
    </div>
  );
};

export default AttributesCustomization;