// ConceptList.jsx

import React from 'react';

function ConceptList({ items, setItems, handleReturnToAvailable }) {
  const moveUp = (index) => {
    if (index === 0) return;
    setItems((prev) => {
      const newArr = [...prev];
      [newArr[index - 1], newArr[index]] = [newArr[index], newArr[index - 1]];
      return newArr;
    });
  };

  const moveDown = (index) => {
    setItems((prev) => {
      if (index === prev.length - 1) return prev;
      const newArr = [...prev];
      [newArr[index], newArr[index + 1]] = [newArr[index + 1], newArr[index]];
      return newArr;
    });
  };

  const indent = (index) => {
    setItems((prev) => {
      const newArr = [...prev];
      if (index === 0) return prev;
      if (newArr[index - 1].level < newArr[index].level + 1) {
        newArr[index] = { ...newArr[index], level: newArr[index].level + 1 };
      }
      return newArr;
    });
  };

  const outdent = (index) => {
    setItems((prev) => {
      const newArr = [...prev];
      const oldLevel = newArr[index].level;
      if (oldLevel > 0) {
        newArr[index] = { ...newArr[index], level: oldLevel - 1 };
      }
      return newArr;
    });
  };

  return (
    <div className="editable-list-container">
      <h3>Llista editable</h3>
      <div className="list-container">
        {items.map((item, index) => (
          <div
            key={item.id}
            className="editable-list-item"
            style={{
              marginLeft: `${item.level * 30}px`,
            }}
          >
            <span className="list-item-label">{item.label}</span>
            <div className="editable-button-container">
              <button className="editable-button" onClick={() => moveUp(index)}>
                ↑
              </button>
              <button className="editable-button" onClick={() => moveDown(index)}>
                ↓
              </button>
              <button className="editable-button" onClick={() => indent(index)}>
                →
              </button>
              <button className="editable-button" onClick={() => outdent(index)}>
                ←
              </button>
              <button
                className="editable-button-minus"
                onClick={() => handleReturnToAvailable(index)}
                >
                &ndash; {/* Guió llarg */}
                </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ConceptList;
