// CAPEVRForm.jsx

// Comentaris generals sobre l'estat del projecte:

// TODO: El projecte CAPE-Vr està preparat per connectar-se al backend,
// però encara no es farà la integració fins que es publiqui la versió definitiva del CAPE-Vr.
// Aquesta decisió permet assegurar que tots els canvis i adaptacions
// necessaris es facin abans d'iniciar la connexió amb la base de dades.

// INFO: El nou esquema de dades es troba al fitxer `nouEsquemaDeDades`.
// Aquest esquema encara no està en ús i està separat de la lògica actual.
// Quan la versió definitiva estigui disponible, aquest fitxer s'utilitzarà
// per substituir l'esquema de dades antic.

// Planificació futura:

// 1. Revisar el nou esquema de dades (`nouEsquemaDeDades`) respecte a la versió final del CAPE-Vr.
// 2. Actualitzar qualsevol detall o estructura necessària segons les noves especificacions.
// 3. Substituir l'esquema de dades antic pel nou a la connexió del backend.
// 4. Realitzar proves exhaustives per garantir que no hi hagi problemes amb la integració.
// 5. Gestionar possibles migracions de dades si hi ha dades antigues que necessitin adaptar-se al nou esquema.

// Avantatges d'aquesta estratègia:
// - Redueix el risc de duplicació d'esforços si hi ha canvis en la versió final.
// - Permet tenir una base de codi neta i preparada per a adaptacions futures.
// - Facilita la gestió de canvis sense impactar l'estructura actual.

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Demographics from './Demographics';
import RecordingConditions from './RecordingConditions';
import VocalTasks from './VocalTasks';
import RatingConditions from './RatingConditions';
import StandardVocalAttributesRating from './StandardVocalAttributesRating';
import OtherVocalAttributesRating from './OtherVocalAttributesRating';
import PitchLoudnessEvaluation from './PitchLoudnessEvaluation';
import ResonanceNasalityEvaluation from './ResonanceNasalityEvaluation';
import InconsistenciesEvaluation from './InconsistenciesEvaluation';
import InstabilitiesEvaluation from './InstabilitiesEvaluation';
import AdditionalFeaturesEvaluation from './AdditionalFeaturesEvaluation';
import OverallImpression from './OverallImpression';
import Citation from './Citation';

function CAPEVRForm() {
  const { t } = useTranslation();

  // Estats per a Demographics
  const [clientId, setClientId] = useState('');
  const [gender, setGender] = useState('');
  const [age, setAge] = useState('');
  const [examiner, setExaminer] = useState('');
  const [recordingDate, setRecordingDate] = useState('');

  // Estats per a RecordingConditions
  const [recordedAudio, setRecordedAudio] = useState('');
  const [sessionType, setSessionType] = useState('');
  const [recordingEnvironment, setRecordingEnvironment] = useState('');
  const [recordingDeviceAndPlatform, setRecordingDeviceAndPlatform] = useState('');
  const [mouthToMicDistance, setMouthToMicDistance] = useState('');

  // Estat per a CAPEVRVocalTasks
  const [modeledSentences, setModeledSentences] = useState(false);

  // Estats per a RatingConditions
  const [voiceSource, setVoiceSource] = useState('');
  const [listeningDevice, setListeningDevice] = useState('');
  const [auditoryAnchors, setAuditoryAnchors] = useState('');
  const [rater, setRater] = useState('');
  const [ratingDate, setRatingDate] = useState('');
  const [timesPlayed, setTimesPlayed] = useState('');

  // Estats per a StandardVocalAttributesRating
  const [overallSeverity, setOverallSeverity] = useState(0);
  const [roughness, setRoughness] = useState(0);
  const [breathiness, setBreathiness] = useState(0);
  const [strain, setStrain] = useState(0);

  // Estats per a OtherVocalAttributesRating
  const [instabilitiesValues, setInstabilitiesValues] = useState({});
  const [additionalFeaturesValues, setAdditionalFeaturesValues] = useState({});

  // Estats per a Pitch
  const [normalPitch, setNormalPitch] = useState({ presence: false, attributeComment: '' });
  const [tooHighPitch, setTooHighPitch] = useState({ presence: false, attributeComment: '' });
  const [tooLowPitch, setTooLowPitch] = useState({ presence: false, attributeComment: '' });

  // Estats per a Loudness
  const [normalLoudness, setNormalLoudness] = useState({ presence: false, attributeComment: '' });
  const [tooLoudLoudness, setTooLoudLoudness] = useState({ presence: false, attributeComment: '' });
  const [tooSoftLoudness, setTooSoftLoudness] = useState({ presence: false, attributeComment: '' });

  // Estats per a ResonanceNasalityEvaluation
  const [normalResonance, setNormalResonance] = useState({ presence: false, attributeComment: '' });
  const [frontResonance, setFrontResonance] = useState({ presence: false, attributeComment: '' });
  const [backResonance, setBackResonance] = useState({ presence: false, attributeComment: '' });
  const [normalNasality, setNormalNasality] = useState({ presence: false, attributeComment: '' });
  const [hypernasality, setHypernasality] = useState({ presence: false, attributeComment: '' });
  const [hyponasality, setHyponasality] = useState({ presence: false, attributeComment: '' });

  // Estats per a InconsistenciesEvaluation
  const [vowelsInconsistency, setVowelsInconsistency] = useState({ presence: false, attributeComment: '' });
  const [sentencesInconsistency, setSentencesInconsistency] = useState({ presence: false, attributeComment: '' });
  const [extemporaneousSpeechInconsistency, setExtemporaneousSpeechInconsistency] = useState({ presence: false, attributeComment: '' });

  // Estats per a InstabilitiesEvaluation
  const [selectedInstabilities, setSelectedInstabilities] = useState([]);
  const [otherInstabilityTag, setOtherInstabilityTag] = useState('');

  // Estats per a AdditionalFeaturesEvaluation
  const [selectedAdditionalFeatures, setSelectedAdditionalFeatures] = useState([]);
  const [otherAdditionalFeatureTag, setOtherAdditionalFeatureTag] = useState('');

  // Estat per a OverallImpression
  const [overallImpressionComment, setOverallImpressionComment] = useState('');


  const handleSubmit = (event) => {
    event.preventDefault();
    console.log({
      // Demographics
      clientId,
      gender,
      age,
      examiner,
      recordingDate,                  
  
      // RecordingConditions
      recordedAudio,
      sessionType,
      recordingEnvironment,
      recordingDeviceAndPlatform,
      mouthToMicDistance,
  
      // VocalTasks
      modeledSentences,
  
      // RatingConditions
      voiceSource,
      listeningDevice,
      auditoryAnchors,
      rater,
      ratingDate,            
      timesPlayed,
  
      // StandardVocalAttributesRating
      overallSeverity,
      roughness,
      breathiness,
      strain,

      // OtherVocalAttributesRating
      instabilitiesValues,
      additionalFeaturesValues,
  
      // PitchLoudnessEvaluation
      normalPitch,
      tooHighPitch,
      tooLowPitch,
      normalLoudness,
      tooLoudLoudness,
      tooSoftLoudness,
  
      // ResonanceNasalityEvaluation
      normalResonance,
      frontResonance,
      backResonance,
      normalNasality,
      hypernasality,
      hyponasality,
  
      // InconsistenciesEvaluation
      vowelsInconsistency,
      sentencesInconsistency,
      extemporaneousSpeechInconsistency,
  
      // InstabilitiesEvaluation
      selectedInstabilities,
      otherInstabilityTag,
  
      // AdditionalFeaturesEvaluation
      selectedAdditionalFeatures,
      otherAdditionalFeatureTag,
  
      // OverallImpression
      overallImpressionComment,
    });
  };

  return (
    <form className="capevr-form" onSubmit={handleSubmit}>
      
      <div className="capevr-section">
        <Demographics
        clientId={clientId}
        setClientId={setClientId}
        gender={gender}
        setGender={setGender}
        age={age}
        setAge={setAge}
        examiner={examiner}
        setExaminer={setExaminer}
        recordingDate={recordingDate}
        setRecordingDate={setRecordingDate}
      />
      </div>
      
      <div className="capevr-section">
        <h2>{t('recording_conditions')}</h2>
        <RecordingConditions
          recordedAudio={recordedAudio}
          setRecordedAudio={setRecordedAudio}
          sessionType={sessionType}
          setSessionType={setSessionType}
          recordingEnvironment={recordingEnvironment}
          setRecordingEnvironment={setRecordingEnvironment}
          recordingDeviceAndPlatform={recordingDeviceAndPlatform}
          setRecordingDeviceAndPlatform={setRecordingDeviceAndPlatform}
          mouthToMicDistance={mouthToMicDistance}
          setMouthToMicDistance={setMouthToMicDistance}
        />
      </div>

      <div className="capevr-section">
        <h2>{t('stimuli')}</h2>
        <VocalTasks
          modeledSentences={modeledSentences}
          setModeledSentences={setModeledSentences}
        />
      </div>

      <div className="capevr-section">
        <h2>{t('rating_conditions')}</h2>
        <RatingConditions
          voiceSource={voiceSource}
          setVoiceSource={setVoiceSource}
          listeningDevice={listeningDevice}
          setListeningDevice={setListeningDevice}
          auditoryAnchors={auditoryAnchors}
          setAuditoryAnchors={setAuditoryAnchors}
          rater={rater}
          setRater={setRater}
          ratingDate={ratingDate}
          setRatingDate={setRatingDate}
          timesPlayed={timesPlayed}
          setTimesPlayed={setTimesPlayed}
        />
      </div>

      <div className="capevr-section">
        <h2>{t('rating_form')}</h2>
        <StandardVocalAttributesRating
          overallSeverity={overallSeverity}
          setOverallSeverity={setOverallSeverity}
          roughness={roughness}
          setRoughness={setRoughness}
          breathiness={breathiness}
          setBreathiness={setBreathiness}
          strain={strain}
          setStrain={setStrain}
        />
      </div>

      <div className="capevr-section">
        <OtherVocalAttributesRating
          selectedInstabilities={selectedInstabilities}
          instabilitiesValues={instabilitiesValues}
          setInstabilitiesValues={setInstabilitiesValues}
          selectedAdditionalFeatures={selectedAdditionalFeatures}
          additionalFeaturesValues={additionalFeaturesValues}
          setAdditionalFeaturesValues={setAdditionalFeaturesValues}
          otherInstabilityTag={otherInstabilityTag}
          otherAdditionalFeatureTag={otherAdditionalFeatureTag}
        />
      </div>

      <div className="capevr-section">
      <PitchLoudnessEvaluation
        normalPitch={normalPitch}
        setNormalPitch={setNormalPitch}
        tooHighPitch={tooHighPitch}
        setTooHighPitch={setTooHighPitch}
        tooLowPitch={tooLowPitch}
        setTooLowPitch={setTooLowPitch}
        normalLoudness={normalLoudness}
        setNormalLoudness={setNormalLoudness}
        tooLoudLoudness={tooLoudLoudness}
        setTooLoudLoudness={setTooLoudLoudness}
        tooSoftLoudness={tooSoftLoudness}
        setTooSoftLoudness={setTooSoftLoudness}
      />
      </div>

      <div className="capevr-section">
      <ResonanceNasalityEvaluation
        normalResonance={normalResonance}
        setNormalResonance={setNormalResonance}
        frontResonance={frontResonance}
        setFrontResonance={setFrontResonance}
        backResonance={backResonance}
        setBackResonance={setBackResonance} 
        normalNasality={normalNasality}
        setNormalNasality={setNormalNasality}
        hypernasality={hypernasality}
        setHypernasality={setHypernasality}
        hyponasality={hyponasality}
        setHyponasality={setHyponasality}
      />
      </div>
      <div className="capevr-section">
      <div className="capevr-section">
        <InconsistenciesEvaluation
          vowelsInconsistency={vowelsInconsistency}
          setVowelsInconsistency={setVowelsInconsistency}
          sentencesInconsistency={sentencesInconsistency}
          setSentencesInconsistency={setSentencesInconsistency}
          extemporaneousSpeechInconsistency={extemporaneousSpeechInconsistency}
          setExtemporaneousSpeechInconsistency={setExtemporaneousSpeechInconsistency}
        />
      </div>
      </div>
      <div className="capevr-section">
      <InstabilitiesEvaluation
        selectedInstabilities={selectedInstabilities}
        setSelectedInstabilities={setSelectedInstabilities}
        instabilitiesValues={instabilitiesValues} // Afegit
        setInstabilitiesValues={setInstabilitiesValues} // Afegit
        otherInstabilityTag={otherInstabilityTag}
        setOtherInstabilityTag={setOtherInstabilityTag}
      />
      </div>
      <div className="capevr-section">
        <AdditionalFeaturesEvaluation
          selectedAdditionalFeatures={selectedAdditionalFeatures}
          setSelectedAdditionalFeatures={setSelectedAdditionalFeatures}
          additionalFeaturesValues={additionalFeaturesValues} // Afegit
          setAdditionalFeaturesValues={setAdditionalFeaturesValues} // Afegit
          otherAdditionalFeatureTag={otherAdditionalFeatureTag}
          setOtherAdditionalFeatureTag={setOtherAdditionalFeatureTag}
        />
        </div>
      <div className="capevr-section">
        <OverallImpression
            overallImpressionComment={overallImpressionComment}
            setOverallImpressionComment={setOverallImpressionComment}
        />
      </div>
      <button className="capevr-submit-button" type="submit">
        {t('submit')}
      </button>
      <br/>
      <div className="cites">
        <Citation></Citation>
      </div>
    </form>
  );
}

export default CAPEVRForm;