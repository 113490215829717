// ResonanceNasalityEvaluation.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';

function ResonanceNasalityEvaluation({
  normalResonance,
  setNormalResonance,
  frontResonance,
  setFrontResonance,
  backResonance,
  setBackResonance,
  normalNasality,
  setNormalNasality,
  hypernasality,
  setHypernasality,
  hyponasality,
  setHyponasality,
}) {
  const { t } = useTranslation();

  const handleResonanceSelection = (type) => {
    setNormalResonance({ ...normalResonance, presence: type === 'normal' });
    setFrontResonance({ ...frontResonance, presence: type === 'front' });
    setBackResonance({ ...backResonance, presence: type === 'back' });
  };

  const handleNasalitySelection = (type) => {
    setNormalNasality({ ...normalNasality, presence: type === 'normal' });
    setHypernasality({ ...hypernasality, presence: type === 'hypernasality' });
    setHyponasality({ ...hyponasality, presence: type === 'hyponasality' });
  };

  return (
    <div>
      {/* Resonance */}
      <div className="capevr-radio-group">
        <label className="capevr-radio-label">{t('resonance')}</label>
        <div className="capevr-radio-buttons-and-textarea">
          <div className="capevr-radio-buttons">
            <label>
              <input
                type="radio"
                name="resonance"
                checked={normalResonance.presence}
                onChange={() => handleResonanceSelection('normal')}
              />
              {t('normal')}
            </label>
            <label>
              <input
                type="radio"
                name="resonance"
                checked={frontResonance.presence}
                onChange={() => handleResonanceSelection('front')}
              />
              {t('front')}
            </label>
            <label>
              <input
                type="radio"
                name="resonance"
                checked={backResonance.presence}
                onChange={() => handleResonanceSelection('back')}
              />
              {t('back')}
            </label>
          </div>

          {/* Comentari associat a Resonance */}
          {(normalResonance.presence || frontResonance.presence || backResonance.presence) && (
            <textarea
              id="resonanceComment"
              value={
                normalResonance.presence
                  ? normalResonance.attributeComment
                  : frontResonance.presence
                  ? frontResonance.attributeComment
                  : backResonance.presence
                  ? backResonance.attributeComment
                  : ''
              }
              onChange={(e) => {
                if (normalResonance.presence) {
                  setNormalResonance({ ...normalResonance, attributeComment: e.target.value });
                } else if (frontResonance.presence) {
                  setFrontResonance({ ...frontResonance, attributeComment: e.target.value });
                } else if (backResonance.presence) {
                  setBackResonance({ ...backResonance, attributeComment: e.target.value });
                }
              }}
              placeholder={t('comment')}
              aria-label={t('resonance_comment')}
              className="capevr-textarea"
            />
          )}
        </div>
      </div>

      {/* Nasality */}
      <div className="capevr-radio-group">
        <label className="capevr-radio-label">{t('nasality')}</label>
        <div className="capevr-radio-buttons-and-textarea">
          <div className="capevr-radio-buttons">
            <label>
              <input
                type="radio"
                name="nasality"
                checked={normalNasality.presence}
                onChange={() => handleNasalitySelection('normal')}
              />
              {t('normal')}
            </label>
            <label>
              <input
                type="radio"
                name="nasality"
                checked={hypernasality.presence}
                onChange={() => handleNasalitySelection('hypernasality')}
              />
              {t('hypernasality')}
            </label>
            <label>
              <input
                type="radio"
                name="nasality"
                checked={hyponasality.presence}
                onChange={() => handleNasalitySelection('hyponasality')}
              />
              {t('hyponasality')}
            </label>
          </div>

          {/* Comentari associat a Nasality */}
          {(normalNasality.presence || hypernasality.presence || hyponasality.presence) && (
            <textarea
              id="nasalityComment"
              value={
                normalNasality.presence
                  ? normalNasality.attributeComment
                  : hypernasality.presence
                  ? hypernasality.attributeComment
                  : hyponasality.presence
                  ? hyponasality.attributeComment
                  : ''
              }
              onChange={(e) => {
                if (normalNasality.presence) {
                  setNormalNasality({ ...normalNasality, attributeComment: e.target.value });
                } else if (hypernasality.presence) {
                  setHypernasality({ ...hypernasality, attributeComment: e.target.value });
                } else if (hyponasality.presence) {
                  setHyponasality({ ...hyponasality, attributeComment: e.target.value });
                }
              }}
              placeholder={t('comment')}
              aria-label={t('nasality_comment')}
              className="capevr-textarea"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ResonanceNasalityEvaluation;