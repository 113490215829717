// TallerCAPEVSession.jsx

import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import CAPEVForm from '../../cape-v-shared-files/CAPEVForm';
import CAPEVCitation from '../../cape-v-shared-files/CAPEVCitation';
import dadesMostresCursCLC from '../../../feedback-data/dadesMostresTaller';

// Definim l'URL de l'API utilitzant la variable d'entorn
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';

const TallerCAPEVSession = ({ sessionCode, selectedSample, studentName, onResultsSubmitted }) => {
  const { t } = useTranslation();
  const [sample, setSample] = useState(null);

  const fetchSessionDetails = useCallback(() => {
    axios.get(`${apiUrl}/sessions/${sessionCode}`)
      .then(response => {
        const session = response.data;
        const sampleData = dadesMostresCursCLC.find(s => s.sampleName === session.selectedSample);
        setSample(sampleData);
      })
      .catch(error => {
        console.error('Error fetching session details:', error);
      });
  }, [sessionCode]);

  useEffect(() => {
    fetchSessionDetails();
  }, [fetchSessionDetails]);

  useEffect(() => {
    if (selectedSample !== null) {
      const sampleData = dadesMostresCursCLC.find(s => s.sampleName === selectedSample);
      setSample(sampleData);
    }
  }, [selectedSample]);

  const handleSubmit = (formData) => {
    if (!sample) {
      alert(t('select_a_sample'));
      return;
    }

    if (!formData || !formData.overallSeverity || !formData.roughness || !formData.breathiness || !formData.strain || !formData.pitch || !formData.loudness) {
      console.error('Invalid formData structure:', formData);
      return;
    }

    const updatedFormData = {
      ...formData,
      sampleName: sample.sampleName || t('sampleName')
    };

    axios.post(`${apiUrl}/sessions/submit`, { code: sessionCode, studentName, results: updatedFormData })
      .then(response => {
        alert(t('evaluation_submitted_successfully'));
        onResultsSubmitted(updatedFormData);
      })
      .catch(error => {
        console.error('Error submitting ratings:', error);
        alert(t('error_submitting_ratings'));
      });
  };

  return (
    <div>
      <h1>{t('cape_v_workshop_student_view')}</h1>
      <h2>{t('session_code')}: {sessionCode}</h2>
      <h2>{t('student_name')}: {studentName}</h2>

      {sample && (
        <div className="audio-container">
          <h2>{sample.name}</h2>
          <audio controls src={sample.sampleLink}>
            {t('audio_not_supported')}
          </audio>
        </div>
      )}

      <br />
      <CAPEVForm onSubmit={handleSubmit} sampleData={sample} />
      <br />

      <div className="cites">
        <CAPEVCitation />
      </div>
      <br />
    </div>
  );
}

export default TallerCAPEVSession;