// dadesMostresCursCLC.js

// TODO Esborrar les mostres del Josep Maria, aquí i a la carpeta de mostres

const dadesMostresCursCLC = [
    
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V003","sampleName": "V003","sampleLink": "samples/thesis/concatenated/catalan/V003.wav","age": 19,"gender": "F","reasonForConsultation": "Em quedava sovint afònica i la meva profe de cant em va dir que tenia la veu massa greu. Vinc perquè des de fa un any cada dia em quedava afònica, a la mínima que sortia de festa també i em vaig començar a preocupar.","diagnose": "Quist intracordal. Sulcus vocalis","useSpokenVoice": "No professional ni semiprofessional.","useSingingVoice": "No professional ni semiprofessional (en família, amb els amics, a la dutxa, al cotxe, etc.)","vhi10": "22","language": "catalan","overallSeverity": [[60,41]],"roughness": [[30,38]],"breathiness": [[60,40]],"strain": [[10,30]],"pitch": [[50,32]],"loudness": [[30,0]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V015","sampleName": "V015","sampleLink": "samples/thesis/concatenated/spanish/V015.wav","age": 76,"gender": "F","reasonForConsultation": "Va a logopèdia pel problema de veu perquè no pot parlar de pressa ni menjar de pressa. Paràlisi de CVE deguda a una cirurgia de pulmó","diagnose": "Paràlisi laríngia","useSpokenVoice": "No professional ni semiprofessional.","useSingingVoice": "No canto mai","vhi10": "27","language": "spanish","overallSeverity": [[67,35]],"roughness": [[45,29]],"breathiness": [[57,27]],"strain": [[10,67]],"pitch": [[60,17]],"loudness": [[32,23]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V024","sampleName": "V024","sampleLink": "samples/thesis/concatenated/spanish/V024.wav","age": 59,"gender": "F","reasonForConsultation": "Tenía afonías y se me iba la voz y el ORL me mandó al logopeda","diagnose": "Disfonia funcional","useSpokenVoice": "No professional ni semiprofessional.","useSingingVoice": "No canto mai","vhi10": "26","language": "spanish","overallSeverity": [[50,54]],"roughness": [[50,36]],"breathiness": [[62,49]],"strain": [[30,12]],"pitch": [[0,26]],"loudness": [[10,9]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V033","sampleName": "V033","sampleLink": "samples/thesis/concatenated/catalan/V033.wav","age": 25,"gender": "M","reasonForConsultation": "M'han diagnosticat un pòlip angiomatós. Està fent rehabilitació preperatoria","diagnose": "Pòlip","useSpokenVoice": "No professional ni semiprofessional.","useSingingVoice": "No professional ni semiprofessional (en família, amb els amics, a la dutxa, al cotxe, etc.)","vhi10": "12","language": "catalan","overallSeverity": [[50,41]],"roughness": [[40,42]],"breathiness": [[50,32]],"strain": [[0,37]],"pitch": [[52,0]],"loudness": [[0,17]],},
    {"database": "Vila-Rovira, J.M.","participantId": "B041","sampleName": "B041","sampleLink": "samples/vila-rovira/B041.wav","age": 0,"gender": "","reasonForConsultation": "","diagnose": "","useSpokenVoice": "","useSingingVoice": "","vhi10": "","language": "","overallSeverity": [],"roughness": [],"breathiness": [],"strain": [],"pitch": [],"loudness": [],},
    {"database": "Vila-Rovira, J.M.","participantId": "B057","sampleName": "B057","sampleLink": "samples/vila-rovira/B057.wav","age": 0,"gender": "","reasonForConsultation": "","diagnose": "","useSpokenVoice": "","useSingingVoice": "","vhi10": "","language": "","overallSeverity": [],"roughness": [],"breathiness": [],"strain": [],"pitch": [],"loudness": [],},
    {"database": "Vila-Rovira, J.M.","participantId": "GC-002","sampleName": "GC-002","sampleLink": "samples/vila-rovira/GC-002.wav","age": 0,"gender": "","reasonForConsultation": "","diagnose": "","useSpokenVoice": "","useSingingVoice": "","vhi10": "","language": "","overallSeverity": [],"roughness": [],"breathiness": [],"strain": [],"pitch": [],"loudness": [],},
    {"database": "Vila-Rovira, J.M.","participantId": "GE-017","sampleName": "GE-017","sampleLink": "samples/vila-rovira/GE-017.wav","age": 0,"gender": "","reasonForConsultation": "","diagnose": "","useSpokenVoice": "","useSingingVoice": "","vhi10": "","language": "","overallSeverity": [],"roughness": [],"breathiness": [],"strain": [],"pitch": [],"loudness": [],},
    {"database": "Vila-Rovira, J.M.","participantId": "TO72","sampleName": "TO72","sampleLink": "samples/vila-rovira/TO72.wav","age": 0,"gender": "","reasonForConsultation": "","diagnose": "","useSpokenVoice": "","useSingingVoice": "","vhi10": "","language": "","overallSeverity": [],"roughness": [],"breathiness": [],"strain": [],"pitch": [],"loudness": [],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V001","sampleName": "V001","sampleLink": "samples/thesis/concatenated/catalan/V001.wav","age": 53,"gender": "F","reasonForConsultation": "Pateixo inestabilitat vocal a causa d'una paràlisi cerebral. Vaig tenir una paràlisi cerebral de petita. Em va afectar el cerebel , que és el que controla els moviments automàtics i, per tant, em va afectar la veu.","diagnose": "Disàrtria","useSpokenVoice": "No professional ni semiprofessional.","useSingingVoice": "No professional ni semiprofessional (en família, amb els amics, a la dutxa, al cotxe, etc.)","vhi10": "20","language": "catalan","overallSeverity": [[40,19]],"roughness": [[5,10]],"breathiness": [[40,11]],"strain": [[80,70]],"pitch": [[20,5]],"loudness": [[40,23]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V002","sampleName": "V002","sampleLink": "samples/thesis/concatenated/catalan/V002.wav","age": 67,"gender": "F","reasonForConsultation": "Pòlip en un plec vocal. Em sento afònica, amb la veu ronca, com si fos un home. Tinc una empresa familiar.","diagnose": "Pòlip","useSpokenVoice": "Professional (entrenador/a, monitor/a, operador/a, actor o actriu, etc.).","useSingingVoice": "No canto mai","vhi10": "12","language": "catalan","overallSeverity": [[50,33]],"roughness": [[20,28]],"breathiness": [[50,37]],"strain": [[0,28]],"pitch": [[40,25]],"loudness": [[40,27]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V007","sampleName": "V007","sampleLink": "samples/thesis/concatenated/catalan/V007.wav","age": 72,"gender": "F","reasonForConsultation": "Té espasmes a l'esòfag, l'oprimeix i no la deixa parlar. Inspiracions sonores i parla en inspiració. Si es posa nerviosa és pitjor. Pateix també la síndrome de les cames inquietes. La disfonia apareix arrel de la mort del seu fill en un accident.Ha rebut tractament psicològic i s'ha medicat per això. TME /s/=10 i TMF/a/=14.","diagnose": "Disfonia espàstica","useSpokenVoice": "No professional ni semiprofessional.","useSingingVoice": "No canto mai","vhi10": "11","language": "catalan","overallSeverity": [[75,68]],"roughness": [[70,62]],"breathiness": [[80,37]],"strain": [[60,68]],"pitch": [[40,36]],"loudness": [[45,38]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V008","sampleName": "V008","sampleLink": "samples/thesis/concatenated/catalan/V008.wav","age": 76,"gender": "F","reasonForConsultation": "Operada de tiroides dues vegades. El segon cop va ser una tiroidectomia total. La veu li ha quedat afectada. Li ha baixat molt la intensitat i a vegades no m'entenen o no em senten. Ve a tractament de disfàgia. Interpretem que si la veu li va començar a fallar després de la tiroidectomia és que ha fet una paràlisi laríngia.","diagnose": "Tiroidectomia. Disfàgia. Paràlisi laríngia","useSpokenVoice": "No professional ni semiprofessional.","useSingingVoice": "No professional ni semiprofessional (en família, amb els amics, a la dutxa, al cotxe, etc.)","vhi10": "15","language": "catalan","overallSeverity": [[20,31]],"roughness": [[30,28]],"breathiness": [[10,19]],"strain": [[0,39]],"pitch": [[0,11]],"loudness": [[80,27]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V011","sampleName": "V011","sampleLink": "samples/thesis/concatenated/catalan/V011.wav","age": 28,"gender": "F","reasonForConsultation": "De fa 3 anys te disfàgia d'origen neurològic amb disàrtria i problemes de mobilitat. Implant coclear des de fa 15 anys per pèrdua d'audició progressiva.","diagnose": "Disfàgia. Disàrtria. Hipoacúsia","useSpokenVoice": "No professional ni semiprofessional.","useSingingVoice": "No professional ni semiprofessional (en família, amb els amics, a la dutxa, al cotxe, etc.)","vhi10": "1","language": "catalan","overallSeverity": [[30,8]],"roughness": [[0,5]],"breathiness": [[20,9]],"strain": [[60,18]],"pitch": [[0,4]],"loudness": [[55,35]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V013","sampleName": "V013","sampleLink": "samples/thesis/concatenated/catalan/V013.wav","age": 70,"gender": "F","reasonForConsultation": "Des que em van operar d'edema de Reinke el 24/10/14 que he perdut la veu. No vaig començar logopèdia fins a l'any de la cirurgia.","diagnose": "Edema de Reinke","useSpokenVoice": "No professional ni semiprofessional.","useSingingVoice": "Semiprofessional (cors, cantant aficionat)","vhi10": "32","language": "catalan","overallSeverity": [[52,39]],"roughness": [[48,36]],"breathiness": [[40,34]],"strain": [[20,30]],"pitch": [[0,32]],"loudness": [[0,16]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V017","sampleName": "V017","sampleLink": "samples/thesis/concatenated/catalan/V017.wav","age": 56,"gender": "F","reasonForConsultation": "Sóc mestra d'educació infantil. Treballo amb nens de P4 i P5. Canto en dos cors perquè m'agrada molt cantar.","diagnose": "Disfonia funcional","useSpokenVoice": "Professional (entrenador/a, monitor/a, operador/a, actor o actriu, etc.).","useSingingVoice": "Semiprofessional (cors, cantant aficionat)","vhi10": "17","language": "catalan","overallSeverity": [[0,5]],"roughness": [[0,5]],"breathiness": [[0,1]],"strain": [[10,3]],"pitch": [[0,3]],"loudness": [[0,0]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V018","sampleName": "V018","sampleLink": "samples/thesis/concatenated/catalan/V018.wav","age": 44,"gender": "F","reasonForConsultation": "Sóc teleoperadora en un servei d'atenció al client.","diagnose": "Disfonia funcional hipocinètica","useSpokenVoice": "Professional (entrenador/a, monitor/a, operador/a, actor o actriu, etc.).","useSingingVoice": "No canto mai","vhi10": "35","language": "catalan","overallSeverity": [[80,58]],"roughness": [[0,34]],"breathiness": [[80,59]],"strain": [[10,13]],"pitch": [[50,8]],"loudness": [[90,39]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V019","sampleName": "V019","sampleLink": "samples/thesis/concatenated/catalan/V019.wav","age": 29,"gender": "F","reasonForConsultation": "Sóc mestra, crido molt amb els nens i pateixo disfonia al llarg del curs","diagnose": "Disfonia funcional","useSpokenVoice": "Professional (entrenador/a, monitor/a, operador/a, actor o actriu, etc.).","useSingingVoice": "No professional ni semiprofessional (en família, amb els amics, a la dutxa, al cotxe, etc.)","vhi10": "18","language": "catalan","overallSeverity": [[2,3]],"roughness": [[5,1]],"breathiness": [[0,2]],"strain": [[0,0]],"pitch": [[0,0]],"loudness": [[0,0]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V020","sampleName": "V020","sampleLink": "samples/thesis/concatenated/catalan/V020.wav","age": 57,"gender": "M","reasonForConsultation": "Vaig ser fumador important durant molts anys i vaig ser intervingut quirúrgicament fa un any per carcinoma insitu a la CVE. Acabo el tractament logopèdic d'aquí 3 sessions.","diagnose": "Carcinoma in situ","useSpokenVoice": "Professional (entrenador/a, monitor/a, operador/a, actor o actriu, etc.).","useSingingVoice": "No professional ni semiprofessional (en família, amb els amics, a la dutxa, al cotxe, etc.)","vhi10": "15","language": "catalan","overallSeverity": [[49,37]],"roughness": [[20,20]],"breathiness": [[55,39]],"strain": [[0,12]],"pitch": [[0,26]],"loudness": [[60,0]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V023","sampleName": "V023","sampleLink": "samples/thesis/concatenated/catalan/V023.wav","age": 43,"gender": "F","reasonForConsultation": "L'ORL li va diagnosticar un engruiximent a CVE i no sap si és un nòdul. Per la feina té moltes disfonies.","diagnose": "Disfonia funcional","useSpokenVoice": "Semiprofessional (associacions, teatre, ONG, etc.).","useSingingVoice": "Semiprofessional (cors, cantant aficionat)","vhi10": "29","language": "catalan","overallSeverity": [[10,11]],"roughness": [[0,13]],"breathiness": [[20,20]],"strain": [[10,5]],"pitch": [[0,0]],"loudness": [[0,0]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V026","sampleName": "V026","sampleLink": "samples/thesis/concatenated/catalan/V026.wav","age": 45,"gender": "F","reasonForConsultation": "Nòduls i edema des de fa un any. Vinc a veure si puc millorar. Fa un any que tinc problemes de veu una mica greus. Estic pendent d'unes proves clíniques per veure com estan els nòduls.","diagnose": "Nòduls vocals. Edema","useSpokenVoice": "Professional (entrenador/a, monitor/a, operador/a, actor o actriu, etc.).","useSingingVoice": "Professional (cantant, estudiant de cant, professor de música, etc.)","vhi10": "30","language": "catalan","overallSeverity": [[58,52]],"roughness": [[40,39]],"breathiness": [[65,60]],"strain": [[60,34]],"pitch": [[40,23]],"loudness": [[50,11]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V028","sampleName": "V028","sampleLink": "samples/thesis/concatenated/catalan/V028.wav","age": 49,"gender": "F","reasonForConsultation": "Paràlisi laríngia secundària a tos ferina. Vaig tenir la tos ferina al mes de maig i la toxina em va paralitzar la corda vocal.","diagnose": "Paràlisi laríngia","useSpokenVoice": "Professional (entrenador/a, monitor/a, operador/a, actor o actriu, etc.).","useSingingVoice": "No professional ni semiprofessional (en família, amb els amics, a la dutxa, al cotxe, etc.)","vhi10": "32","language": "catalan","overallSeverity": [[50,40]],"roughness": [[20,39]],"breathiness": [[50,32]],"strain": [[20,25]],"pitch": [[0,12]],"loudness": [[51,9]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V029","sampleName": "V029","sampleLink": "samples/thesis/concatenated/catalan/V029.wav","age": 35,"gender": "F","reasonForConsultation": "Treballadora social. Ha de fer moltes entrevistes. M'han diagnosticat pòlips a les cordes vocals i abans d'intervenir-me m'han demanat fer logopèdia.","diagnose": "Pòlip","useSpokenVoice": "Professional (entrenador/a, monitor/a, operador/a, actor o actriu, etc.).","useSingingVoice": "No professional ni semiprofessional (en família, amb els amics, a la dutxa, al cotxe, etc.)","vhi10": "9","language": "catalan","overallSeverity": [[30,42]],"roughness": [[40,38]],"breathiness": [[30,35]],"strain": [[30,51]],"pitch": [[40,25]],"loudness": [[40,27]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V030","sampleName": "V030","sampleLink": "samples/thesis/concatenated/catalan/V030.wav","age": 12,"gender": "F","reasonForConsultation": "Estava disfònica molt sovint i el pediatre la va derivar a l'ORL i aquest al servei de logopèdia.","diagnose": "Disfonia funcional","useSpokenVoice": "No professional ni semiprofessional.","useSingingVoice": "No professional ni semiprofessional (en família, amb els amics, a la dutxa, al cotxe, etc.)","vhi10": "13","language": "catalan","overallSeverity": [[55,49]],"roughness": [[40,39]],"breathiness": [[50,35]],"strain": [[30,37]],"pitch": [[0,9]],"loudness": [[50,20]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V031","sampleName": "V031","sampleLink": "samples/thesis/concatenated/catalan/V031.wav","age": 11,"gender": "F","reasonForConsultation": "Quist intracordal en un plec vocal i edema en l'altre per contacte. Presenta també un quadre al·lèrgic agut. Porta 3 anys de vacunacions. Juntament amb deglució atípica que també s'ha treballat amb logopèdia. Porta un total de 3 anys de tractament logopèdic. Crida molt.","diagnose": "Quist intracordal. Edema. Deglució atípica","useSpokenVoice": "No professional ni semiprofessional.","useSingingVoice": "No professional ni semiprofessional (en família, amb els amics, a la dutxa, al cotxe, etc.)","vhi10": "13","language": "catalan","overallSeverity": [[40,34]],"roughness": [[0,26]],"breathiness": [[50,39]],"strain": [[30,16]],"pitch": [[0,0]],"loudness": [[30,17]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V032","sampleName": "V032","sampleLink": "samples/thesis/concatenated/catalan/V032.wav","age": 52,"gender": "F","reasonForConsultation": "Mestra i canta en un cor. És un postoperatori. M'han tret un pòlip aquesta primavera.","diagnose": "Pòlip (postquirúrgic)","useSpokenVoice": "Professional (entrenador/a, monitor/a, operador/a, actor o actriu, etc.).","useSingingVoice": "Professional (cantant, estudiant de cant, professor de música, etc.)","vhi10": "10","language": "catalan","overallSeverity": [[10,42]],"roughness": [[10,37]],"breathiness": [[0,20]],"strain": [[22,33]],"pitch": [[40,26]],"loudness": [[30,15]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V034","sampleName": "V034","sampleLink": "samples/thesis/concatenated/catalan/V034.wav","age": 18,"gender": "F","reasonForConsultation": "Canto en un cor i també canto jazz i noto que no puc fer pianos als aguts. M'han diagnosticat nòduls vocals i m'han dit que he de fer logopèdia","diagnose": "Nòduls vocals","useSpokenVoice": "No professional ni semiprofessional.","useSingingVoice": "Semiprofessional (cors, cantant aficionat)","vhi10": "16","language": "catalan","overallSeverity": [[25,31]],"roughness": [[0,20]],"breathiness": [[20,29]],"strain": [[0,23]],"pitch": [[0,6]],"loudness": [[0,20]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V035","sampleName": "V035","sampleLink": "samples/thesis/concatenated/catalan/V035.wav","age": 10,"gender": "F","reasonForConsultation": "La professora de piano va notar que no arribava bé a les notes i desafinava","diagnose": "Disfonia funcional","useSpokenVoice": "No professional ni semiprofessional.","useSingingVoice": "No professional ni semiprofessional (en família, amb els amics, a la dutxa, al cotxe, etc.)","vhi10": "8","language": "catalan","overallSeverity": [[10,15]],"roughness": [[8,13]],"breathiness": [[12,18]],"strain": [[0,8]],"pitch": [[0,0]],"loudness": [[0,0]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V037","sampleName": "V037","sampleLink": "samples/thesis/concatenated/catalan/V037.wav","age": 28,"gender": "F","reasonForConsultation": "Té una faringitis crònica","diagnose": "Faringitis crònica","useSpokenVoice": "Professional (entrenador/a, monitor/a, operador/a, actor o actriu, etc.).","useSingingVoice": "Professional (cantant, estudiant de cant, professor de música, etc.)","vhi10": "15","language": "catalan","overallSeverity": [[0,3]],"roughness": [[0,2]],"breathiness": [[0,2]],"strain": [[0,0]],"pitch": [[0,0]],"loudness": [[0,0]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V004","sampleName": "V004","sampleLink": "samples/thesis/concatenated/spanish/V004.wav","age": 58,"gender": "F","reasonForConsultation": "Nódulo cuerda vocal. Me iban a operar pero a través de logopedia se ha reducido. Me haciendo controles y voy haciendo logopedia.","diagnose": "Nòduls vocals","useSpokenVoice": "No professional ni semiprofessional.","useSingingVoice": "No professional ni semiprofessional (en família, amb els amics, a la dutxa, al cotxe, etc.)","vhi10": "5","language": "spanish","overallSeverity": [[57,43]],"roughness": [[40,34]],"breathiness": [[60,37]],"strain": [[20,26]],"pitch": [[0,18]],"loudness": [[40,15]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V010","sampleName": "V010","sampleLink": "samples/thesis/concatenated/spanish/V010.wav","age": 68,"gender": "M","reasonForConsultation": "Ve per disfàgia. Yo he tenido 4 ictus en un año. De estos ictus tengo una disfagia. Ahora aún se me entiende algo pero hace poco no se me entendía absolutamente nada.","diagnose": "Disàrtria. Pòlip (postquirúrgic). Disfàgia","useSpokenVoice": "No professional ni semiprofessional.","useSingingVoice": "No canto mai","vhi10": "12","language": "spanish","overallSeverity": [[97,92]],"roughness": [[100,92]],"breathiness": [[95,92]],"strain": [[90,20]],"pitch": [[80,66]],"loudness": [[0,0]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V016","sampleName": "V016","sampleLink": "samples/thesis/concatenated/spanish/V016.wav","age": 43,"gender": "F","reasonForConsultation": "De pronto me pongo ronca por reflujo.","diagnose": "Reflux gatroesofàgic","useSpokenVoice": "No professional ni semiprofessional.","useSingingVoice": "Semiprofessional (cors, cantant aficionat)","vhi10": "17","language": "spanish","overallSeverity": [[0,3]],"roughness": [[0,3]],"breathiness": [[0,2]],"strain": [[0,0]],"pitch": [[0,0]],"loudness": [[40,0]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V021","sampleName": "V021","sampleLink": "samples/thesis/concatenated/spanish/V021.wav","age": 47,"gender": "F","reasonForConsultation": "Disfonia de llarga durada amb molèsties. Treballo de cara al públic i perdo molt la veu.","diagnose": "Disfonia funcional","useSpokenVoice": "Professional (entrenador/a, monitor/a, operador/a, actor o actriu, etc.).","useSingingVoice": "No professional ni semiprofessional (en família, amb els amics, a la dutxa, al cotxe, etc.)","vhi10": "26","language": "spanish","overallSeverity": [[10,12]],"roughness": [[0,15]],"breathiness": [[10,9]],"strain": [[0,10]],"pitch": [[0,0]],"loudness": [[0,8]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V022","sampleName": "V022","sampleLink": "samples/thesis/concatenated/spanish/V022.wav","age": 53,"gender": "M","reasonForConsultation": "Me quedo sin voz por estrés y por hablar mucho al cabo de la semana. Tengo un nódulo en un lado y cuando me esfuerzo mucho me quedo sin voz en el trabajo. Siempre el sábado no tengo voz. Me voy recuperando poco a poco.","diagnose": "Nòduls vocals","useSpokenVoice": "Professional (entrenador/a, monitor/a, operador/a, actor o actriu, etc.).","useSingingVoice": "No canto mai","vhi10": "30","language": "spanish","overallSeverity": [[25,15]],"roughness": [[28,15]],"breathiness": [[15,21]],"strain": [[20,8]],"pitch": [[0,0]],"loudness": [[30,0]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V027","sampleName": "V027","sampleLink": "samples/thesis/concatenated/spanish/V027.wav","age": 65,"gender": "F","reasonForConsultation": "Cuerdas vocales atrofiadas. Tenía disfonía continuada. Me quedo sin voz. Tengo las cuerdas vocales endurecidas.","diagnose": "Atròfia de plecs vocals","useSpokenVoice": "No professional ni semiprofessional.","useSingingVoice": "No canto mai","vhi10": "29","language": "spanish","overallSeverity": [[40,47]],"roughness": [[20,36]],"breathiness": [[40,43]],"strain": [[35,32]],"pitch": [[30,16]],"loudness": [[30,14]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V005","sampleName": "V005","sampleLink": "samples/thesis/concatenated/catalan/V005.wav","age": 65,"gender": "F","reasonForConsultation": "Aclarar la voz. Sempre he tingut la veu molt aguda. Estic de cara al públic i de mica en mica m'anava quedant sense veu. Pensava que era per l'edat però anava veient que cada vegada acabava molt esgotada i feia molts esforços.","diagnose": "Hiatus vocal","useSpokenVoice": "No professional ni semiprofessional.","useSingingVoice": "No professional ni semiprofessional (en família, amb els amics, a la dutxa, al cotxe, etc.)","vhi10": "14","language": "catalan","overallSeverity": [[40,25]],"roughness": [[0,22]],"breathiness": [[40,31]],"strain": [[0,7]],"pitch": [[0,16]],"loudness": [[0,0]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V006","sampleName": "V006","sampleLink": "samples/thesis/concatenated/spanish/V006.wav","age": 74,"gender": "F","reasonForConsultation": "Tengo muchos problemas porque me quedo afónica. Esto ya me viene de años.","diagnose": "Hiatus vocal. Faringitis crònica","useSpokenVoice": "No professional ni semiprofessional.","useSingingVoice": "No canto mai","vhi10": "20","language": "spanish","overallSeverity": [[40,42,57,75,35,18,73,34,67,19,40,28,25,11,27,28]],"roughness": [[25,51,57,80,54,21,79,60,70,27,70,28,54,18,43,29]],"breathiness": [[10,22,10,60,12,20,53,26,50,4,22,17,29,0,37,10]],"strain": [[20,10,0,70,0,19,13,23,17,8,21,23,4,23,0]],"pitch": [[0,19,50,85,18,57,0,0,6,0,20,0,0,0,10,0]],"loudness": [[0,0,65,80,0,58,45,0,7,0,13,0,0,7,0,26]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V009","sampleName": "V009","sampleLink": "samples/thesis/concatenated/spanish/V009.wav","age": 56,"gender": "M","reasonForConsultation": "Vengo porque tengo pólipos y en el trabajo necesito la voz.","diagnose": "Pòlip","useSpokenVoice": "Professional (entrenador/a, monitor/a, operador/a, actor o actriu, etc.).","useSingingVoice": "No professional ni semiprofessional (en família, amb els amics, a la dutxa, al cotxe, etc.)","vhi10": "22","language": "spanish","overallSeverity": [[60,21,56,50,22,71,70,45,58,40,61,54,49,8,26,14]],"roughness": [[40,26,58,61,32,74,69,57,68,48,66,52,42,12,37,21]],"breathiness": [[45,20,0,40,23,24,59,35,41,42,58,11,15,0,3,0]],"strain": [[55,19,6,60,19,69,70,35,30,26,47,57,40,8,31,20]],"pitch": [[0,18,28,0,0,50,23,28,20,0,17,0,37,7,0,8]],"loudness": [[35,4,28,70,0,12,61,30,7,10,34,13,22,0,11,20]],},
    {"database": "Calaf, N. & Garcia-Quintana, D. (2024). Development and validation of the bilingual Catalan/Spanish cross-cultural adaptation of the Consensus Auditory-Perceptual Evaluation of Voice (CAPE-V). Journal of Speech, Language, and Hearing Research","participantId": "V036","sampleName": "V036","sampleLink": "samples/thesis/concatenated/catalan/V036.wav","age": 74,"gender": "F","reasonForConsultation": "Va començar a tenir problemes de veu en una època en que la seva germana, que és més gran que ella, convivia amb ella i el seu home i ella es posava molt nerviosa. El seu home li deia que no es posés tan nerviosa perquè es quedava disfònica. En aquell moment era més esporàdic. Ara li passa més sovint i va lligat també a problemes de reflux. Està prenent omeprazol.","diagnose": "Granuloma. Reflux gatroesofàgic","useSpokenVoice": "No professional ni semiprofessional.","useSingingVoice": "No canto mai","vhi10": "27","language": "catalan","overallSeverity": [[90,82,64,95,73,89,94,95,96,87,75,73,80,81,82,64]],"roughness": [[90,59,50,0,64,78,93,55,80,22,40,48,39,59,87,42]],"breathiness": [[87,52,7,60,38,83,91,95,48,54,5,4,12,8,55,31]],"strain": [[90,88,65,95,88,94,95,77,98,69,85,95,87,88,91,64]],"pitch": [[60,33,71,95,37,84,94,80,7,43,70,52,59,50,0,44]],"loudness": [[0,19,69,90,0,55,89,75,67,22,5,6,18,0,0,43]],},
    ];
    
    export default dadesMostresCursCLC;