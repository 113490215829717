// CategoryVisibilityControls.jsx
import React from 'react';

function CategoryVisibilityControls({ fixedOrder, visibleCategories, toggleCategoryVisibility }) {
    return (
        <div className="category-selectors" style={{ marginBottom: '20px' }}>
            {fixedOrder.map((category) => (
                <label key={category} style={{ marginRight: '15px' }}>
                    <input
                        type="checkbox"
                        checked={visibleCategories.includes(category)}
                        onChange={() => toggleCategoryVisibility(category)}
                    />
                    {category}
                </label>
            ))}
        </div>
    );
}

export default CategoryVisibilityControls;